import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { FaRegHeart } from "react-icons/fa";
import { BiCommentDetail } from "react-icons/bi";
import { LuDot } from "react-icons/lu";
import { BsShare } from "react-icons/bs";
import { IoIosHeartEmpty, IoIosHeart } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import TextareaAutosize from 'react-textarea-autosize';
import { MentionsInput, Mention } from 'react-mentions'
import { getAuthHeader, renderShortTimeAgo } from "../utils";
import AnonymousAvatar from "../assets/hilo-avatar.png"
import CommentToolTip from "./CommentToolTip.tsx";
import CommentMedia from "./CommentMedia";
import { isDuplicate, isEmptyString, mapToEmoji, getUniqueReactions } from "../utils";
import { emojis, emojiIDs } from "../emojis";
import AuthContext from "./AuthProvider";
import useWindowDimensions from "../hooks/useWindowSize.ts";
import { AiOutlineLoading } from "react-icons/ai";
import "../styles/Mentions.css";
import { ReactionButton } from "./ReactionButton.tsx";
import ReactionComponent from "./ReactionComponent.tsx";

import { Comment } from "../types/Comment";
import { Reaction } from "../types/Post";
import { BaseHeaderType } from "../types/Auth";
import { SuggestionDataItem } from "../types/Mentions.ts";
import { YoutubeInfo, TwitchInfo } from "../types/Integrations";
import { User } from "../types/User.ts";


const MentionsStyle = {
  backgroundColor: '#41487a',
  borderRadius: '5px',
  padding: '1px 2px',
  margin: '-2px'
}
const EmojisStyle = {
  backgroundColor: 'transparent',
}

interface MentionsInputChangeEvent {
  target: { value: string };
}

interface CommentProps {
  postID: string | number;
  currUserInfo: User;
  mentions?: SuggestionDataItem[];
}


const Comments = ({ postID, currUserInfo, mentions=[]}: CommentProps) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [numComments, setNumComments] = useState(0);
  const [topComments, setTopComments] = useState<Comment[]>([]);
  const [likedComments, setLikedComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState("");
  const [showCreateComment, setShowCreateComment] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [iconURLs, setIconURLs] = useState([]);

  const [youtubeVideoLink, setYoutubeVideoID] = useState("");

  const [youtubeInfo, setYoutubeInfo] = useState<YoutubeInfo>({
    video_title: "",
    channel_title: "",
    thumbnail_url: "",
  });

  const [twitchInfo, setTwitchInfo] = useState<TwitchInfo>({
    id: "",
    url: "",
    embed_url: "",
    broadcaster_id: "",
    broadcaster_name: "",
    creator_id: "",
    creator_name: "",
    video_id: "",
    game_id: "",
    language: "",
    title: "",
    view_count: 0,
    created_at: "",
    thumbnail_url: "",
    duration: 0,
    vod_offset: 0
  }); 
  const [twitchClipID, setTwitchClipID] = useState("") 


  const [reacters, setReacters] = useState<Reaction[]>([]);
  const [reactions, setReactions] = useState<string[]>([]); 


  const [copied, setCopied] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [showCommentErr, setShowCommentErr] = useState(false);

  const [showLikers, setShowLikers] = useState(false);
  
  const {user} = useContext(AuthContext);
  const [lockCommentBox, setLockCommentBox] = useState(false);
  const [pastedFile, setPastedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [creating, setCreating] = useState(false);
  const {height, width} = useWindowDimensions()

  useEffect(() => {
    // Get all post comments
    if (postID != null) {
      axios.get(`/posts/${postID}/comments/top`, getAuthHeader())
      .then((response) => {
        
        setTopComments(response.data["top_comments"]);
        setNumComments(Number(response.data["num_comments"]));
        setLikedComments(response.data["top_comments"].filter((comment: Comment) => comment.user_like).map((comment: Comment) => comment.id));  
      });
    }
  }, [trigger]);

  useEffect(() => {
    // Get all post likes
    if (postID != null) {
      axios.get(`/posts/${postID}/reactions`, getAuthHeader())
      .then((response) => {
        //console.log(response.data); 
        //console.log(response); 
        const reactersData: Reaction[] = response.data.map((item: any) => {
          return {
            "user_id" : item[0], 
            "profile_pic": item[1],
            "username": item[2],
            "reaction_type": item[3], 
          };
        });
        const uniqueReactions = getUniqueReactions(reactersData);
        setReacters(reactersData);

        setReactions(uniqueReactions); 
        setIconURLs(response.data.slice(0, 3).map((item: any) => item[1]));
      });
    }
  }, []);

  const handleToggleLikeComment = (comment: Comment) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let isIncrease = false;
    if (!isDuplicate(likedComments, String(comment.id))) {
      setLikedComments([...likedComments, String(comment.id)]);
      isIncrease = true;
    } else {
      setLikedComments(likedComments.filter(item => item !== String(comment.id)));
    }
    if (postID != null) {
      axios.patch(`/posts/${postID}/comments/?id=${comment?.id}`, {
        toggle_like: true,
      }, getAuthHeader())
      .then((response) => {
        const updateComments = (commentsList: Comment[]) => {
          return commentsList.map(currComment => {
            if (comment?.id === currComment.id) {
              currComment.num_likes += isIncrease ? 1 : -1;
            }
            return currComment;
          });
        };

        if (!seeMore) {
          setTopComments(updateComments(topComments));
        } else {
          setComments(updateComments(comments));
        }
      })
    }
  }

  const handleFetchComments = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (postID != null) {
      axios.get(`/posts/${postID}/comments`, getAuthHeader())
      .then((response) => {
        setLikedComments(response.data.filter((comment: Comment) => comment.user_like).map((comment: Comment) => comment.id)); 
        setComments(response.data);
        setTopComments([]);
        setSeeMore(true);
        
      });
    }
  }

  const handleTogglePostLike = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (user != null && postID != null) {
      axios.patch(`/posts/${postID}/reactions/`, {
        user_id: user.user_id,
        toggle: true, 
      }, getAuthHeader())
      .then((response) => { 
        if (response.data["reacted"]) {
          const new_data = [...reacters, {
            "user_id" : currUserInfo?.id, 
            "profile_pic": currUserInfo?.profile_pic,
            "username": currUserInfo?.username,
            "reaction_type": 'heart'
          }]; 
          setReacters(new_data);
          setReactions(getUniqueReactions(new_data)); 
        } else {
          const new_data = reacters.filter(liker => liker["username"] !== currUserInfo?.username); 
          setReacters(new_data);
          setReactions(getUniqueReactions(new_data)); 
        }
        //console.log(reactions); 
        //console.log(response.data["reacted"])
      });
    }
  }

  const handleToggleCreateComment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowCreateComment(!showCreateComment);
  }

  const handlePasteMedia = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const items = e.clipboardData.items;
    const allowedImageTypes = ['image/gif', 'image/png', 'image/jpeg', 'image/jpg'];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
  
      // Check if the item is a file and of allowed image type
      if (item.kind === 'file' && allowedImageTypes.includes(item.type)) {
        const file = item.getAsFile();
        if (file) {
          setPastedFile(file);
          setPreviewUrl(URL.createObjectURL(file));
          break;
        }
      }
    }
    const pastedText = e.clipboardData.getData('Text');
    const youtubeRegex = /(?:https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)|https:\/\/youtu\.be\/([^?&\s]+))/;
    const youtubeMatch = pastedText.match(youtubeRegex);

    const twitchRegex = /https:\/\/(?:www\.)?twitch\.tv\/[^\/]+\/clip\/([^?]+)|https:\/\/clips\.twitch\.tv\/([^?]+)/;
    const twitchMatch = pastedText.match(twitchRegex);

    if (youtubeMatch) {
      const videoId = youtubeMatch[1] || youtubeMatch[2];
      axios.get(`/posts/youtube?video_id=${videoId}`, getAuthHeader())
        .then((response) => {
          setYoutubeInfo(response.data);
          setYoutubeVideoID(videoId);
          const cleanedText = newComment.replace(youtubeRegex, '').trim();
          setNewComment(cleanedText);
        });
    } else if (twitchMatch) {
      const twitchClipID = twitchMatch[1] || twitchMatch[2]; 
      axios.get(`/posts/twitch?clip_id=${twitchClipID}`, getAuthHeader())
      .then((response) => {
        setTwitchInfo(response.data);
        setTwitchClipID(twitchClipID);
        const cleanedText = newComment.replace(twitchRegex, '').trim();
        setNewComment(cleanedText);
      });
    }

  }

  const handleChangeCommentBody = (e: MentionsInputChangeEvent) => {
    setShowCommentErr(false);
    setNewComment(e.target.value);
  }

  const handleCreateComment = (e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isEmptyString(newComment) && pastedFile == null) {
      setShowCommentErr(true);
      return;
    } else {
      setShowCommentErr(false);
    }

    if (lockCommentBox) {
      return
    } else {
      setLockCommentBox(true); 
    }

    // Use regex, parse comment body mention
    let cleanedBody = newComment.replace(/\[(\S*?)\s(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)\]/gu, '$2');
    cleanedBody = cleanedBody.replace(/\(\d+\)/g, "");

    if (user != null && postID != null) {
      const formData = new FormData();

      if (pastedFile != null)
        formData.append('media', pastedFile)
    
      formData.append('post', String(postID))
      formData.append('author', String(user.user_id));
      formData.append('body', cleanedBody);
      formData.append('youtube_video_id', youtubeVideoLink);
      formData.append('twitch_clip_id', twitchClipID);  

      let baseHeaders: BaseHeaderType = getAuthHeader();
      baseHeaders["headers"]["Content-Type"] = "multipart/form-data";
      setCreating(true);

      axios.post(`/posts/${postID}/comments/`, formData, baseHeaders)
      .then((response) => {
        setNewComment("");
        setTrigger(!trigger);
        setSeeMore(false);
        setShowCreateComment(false);
        setLockCommentBox(false);
        setPreviewUrl("");
        setPastedFile(null);
        setCreating(false);
        setYoutubeInfo({
          video_title: "",
          channel_title: "",
          thumbnail_url: "",
        });
        setYoutubeVideoID("");
        setTwitchClipID(""); 
        setTwitchInfo({
          id: "",
          url: "",
          embed_url: "",
          broadcaster_id: "",
          broadcaster_name: "",
          creator_id: "",
          creator_name: "",
          video_id: "",
          game_id: "",
          language: "",
          title: "",
          view_count: 0,
          created_at: "",
          thumbnail_url: "",
          duration: 0,
          vod_offset: 0
        }); 
      })
    } else {
      setLockCommentBox(false);  
    }
  }

  
  const handleSharePost = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCopied(true);
    if (postID != null) {
      navigator.clipboard.writeText(
        `${window.location.origin}/posts/${postID}`
      );
    }
  }

  const renderComment = (comment: Comment) => {
    const mentionRegex = /@\[[a-zA-Z0-9_]+\]/g;
    const urlRegex = /https?:\/\/[^\s]+/g;
  
    // If no mentions or URLs are found, render as plain text
    if (!mentionRegex.test(comment?.body) && !urlRegex.test(comment?.body)) {
      return (
        <div className="flex flex-row sm:px-2 gap-2 items-start">
          <div className="h-6 w-6 sm:h-8 sm:w-8 overflow-hidden rounded-full">
            {comment.author?.profile_pic?.length > 0 ? (
              <img src={comment.author?.profile_pic} className="w-full h-full object-cover" />
            ) : (
              <img src={AnonymousAvatar} className="w-full h-full object-cover" />
            )}
          </div>
  
          <div className="flex flex-col flex-1 gap-1">
            <span className="flex flex-row gap-2 items-center">
              <div
                className="text-sm font-medium hover:cursor-pointer"
                onClick={() => (window.location.href = `/profile/${comment.author?.username}`)}
              >
                {comment.author?.username}
              </div>
              <div className="font-extralight text-[10px] sm:text-[12px] text-white">
                {renderShortTimeAgo(comment?.date_created)}
              </div>
            </span>
            {(comment.body.length > 0) && <p className="text-base font-extralight break-words whitespace-pre-wrap overflow-visible">
              {comment.body}
            </p>}
            {comment?.media && <CommentMedia comment={comment} />}
            {
            (comment.youtube_video_id && comment.youtube_video_id?.length > 0) && 
            <span
              className="p-2 flex flex-col sm:flex-row sm:gap-2 sm:my-2 bg-black rounded-3xl transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(`https://www.youtube.com/watch?v=${comment.youtube_video_id}`);
              }}

            >
              <div>
                <img src={comment.youtube_info["thumbnail_url"]} alt="" className="h-6 w-6 sm:h-6 sm:w-16 sm:rounded-xl"/>
              </div>
              <div className="flex flex-col">
                <p className="font-sans text-[8px] sm:text-[12px] text-[#FFFFFF]">{comment.youtube_info["video_title"]}</p>
                <p className="font-sans text-[4px] sm:text-[8px] text-white]">Channel: {comment.youtube_info["channel_title"]}</p>
              </div>
              <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" alt="" className="ml-auto w-6 sm:w-10 sm:h-8" />
            </span>
            }
            {(comment.twitch_clip_id && comment.twitch_clip_id.length > 0) &&
              <span 
                className="flex flex-row sm:gap-2 sm:my-2 bg-black rounded-3xl p-2 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(comment.twitch_info["url"]);
                }}
              >
                <div>
                  <img src={comment.twitch_info["thumbnail_url"]} alt="" className="h-4 w-6 sm:h-6 sm:w-8 sm:rounded-xl pr-2"/>
                </div>
                <div className="flex flex-col">
                  <p className="font-sans text-[8px] sm:text-[14px] text-[#9146FF]">{comment.twitch_info["title"]}</p>
                  <p className="font-sans text-[4px] sm:text-[10px] text-white]">Streamer: {comment.twitch_info["broadcaster_name"]}</p>
                </div>
                <img src="https://cdn-icons-png.flaticon.com/512/5968/5968819.png" alt="" className="ml-auto w-8 h-6 sm:w-10 sm:h-8" />
              </span>
            }

          </div>
  
          <div className="ml-auto place-self-center flex flex-row sm:gap-2 sm:pr-3 justify-end">
            <p className="text-white text-sm place-self-center">{comment.num_likes}</p>
            <button className="max-sm:pl-2" onClick={handleToggleLikeComment(comment)}>
              {isDuplicate(likedComments, String(comment.id)) ? <IoIosHeart size={20} /> : <IoIosHeartEmpty size={20} />}
            </button>
            <div className="place-self-center">
              {user != null && user.user_id === comment.author.id ? (
                <CommentToolTip
                  postID={postID}
                  comment={comment}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  setSeeMore={setSeeMore}
                />
              ) : (
                <div className="w-4 h-4 sm:w-6 sm:h-6"></div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      // Split the body into segments based on mentions and URLs
      const splitRegex = /(@\[[a-zA-Z0-9_]+\]|https?:\/\/[^\s]+)/g;
      const commentNodes = comment?.body.split(splitRegex);
  
      return (
        <div className="flex flex-row sm:px-2 gap-2 items-start">
          <div className="h-6 w-6 sm:h-8 sm:w-8 overflow-hidden rounded-full">
            {comment.author?.profile_pic?.length > 0 ? (
              <img src={comment.author?.profile_pic} className="w-full h-full object-cover" />
            ) : (
              <img src={AnonymousAvatar} className="w-full h-full object-cover" />
            )}
          </div>
  
          <div className="flex flex-col flex-1 gap-1 overflow-x-hidden">
            <span className="flex flex-row gap-2 items-center">
              <div
                className="text-sm font-medium hover:cursor-pointer"
                onClick={() => (window.location.href = `/profile/${comment.author?.username}`)}
              >
                {comment.author?.username}
              </div>
              <div className="font-extralight text-[10px] sm:text-[12px] text-white">
                {renderShortTimeAgo(comment?.date_created)}
              </div>
            </span>
            <p className="text-base font-extralight break-words whitespace-pre-wrap overflow-visible">
              {commentNodes.map((chunk, index) => {
                const username = chunk.match(/@\[(\w+)\]/);
                const url = chunk.match(urlRegex);
  
                if (username) {
                  // Render mention with specific style
                  return (
                    <React.Fragment key={index}>
                      <span>
                        <a
                          className="text-[#BA99FB] rounded-md py-[1px] px-[2px]"
                          href={`/profile/${username[1]}`}
                        >
                          @{username[1]}
                        </a>
                      </span>
                    </React.Fragment>
                  );
                } else if (url) {
                  return (
                    <React.Fragment key={index}>
                      <a
                        href={url[0]}
                        className="text-[#0FEFFD] underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url[0]}
                      </a>
                    </React.Fragment>
                  );
                } else {
                  // Render plain text
                  return <React.Fragment key={index}>{chunk}</React.Fragment>;
                }
              })}
            </p>
            {comment?.media && <CommentMedia comment={comment} />}
            {
            (comment.youtube_video_id && comment.youtube_video_id?.length > 0) && 
            <span
              className="p-2 flex flex-col sm:flex-row sm:gap-2 sm:my-2 bg-black rounded-3xl transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(`https://www.youtube.com/watch?v=${comment.youtube_video_id}`);
              }}

            >
              <div>
                <img src={comment.youtube_info["thumbnail_url"]} alt="" className="h-6 w-6 sm:h-6 sm:w-16 sm:rounded-xl"/>
              </div>
              <div className="flex flex-col">
                <p className="font-sans text-[8px] sm:text-[12px] text-[#FFFFFF]">{comment.youtube_info["video_title"]}</p>
                <p className="font-sans text-[4px] sm:text-[8px] text-white]">Channel: {comment.youtube_info["channel_title"]}</p>
              </div>
              <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" alt="" className="ml-auto w-6 sm:w-10 sm:h-8" />
            </span>
            }
            {(comment.twitch_clip_id && comment.twitch_clip_id.length > 0) &&
              <span 
                className="flex flex-row sm:gap-2 sm:my-2 bg-black rounded-3xl p-2 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(comment.twitch_info["url"]);
                }}
              >
                <div>
                  <img src={comment.twitch_info["thumbnail_url"]} alt="" className="h-4 w-6 sm:h-6 sm:w-8 sm:rounded-xl pr-2"/>
                </div>
                <div className="flex flex-col">
                  <p className="font-sans text-[8px] sm:text-[14px] text-[#9146FF]">{comment.twitch_info["title"]}</p>
                  <p className="font-sans text-[4px] sm:text-[10px] text-white]">Streamer: {comment.twitch_info["broadcaster_name"]}</p>
                </div>
                <img src="https://cdn-icons-png.flaticon.com/512/5968/5968819.png" alt="" className="ml-auto w-8 h-6 sm:w-10 sm:h-8" />
              </span>
            }

          </div>
  
          <div className="ml-auto place-self-center flex flex-row sm:gap-2 sm:pr-3 justify-end">
            <p className="text-white text-sm place-self-center">{comment.num_likes}</p>
            <button className="max-sm:pl-2" onClick={handleToggleLikeComment(comment)}>
              {isDuplicate(likedComments, String(comment.id)) ? <IoIosHeart size={20} /> : <IoIosHeartEmpty size={20} />}
            </button>
            <div className="place-self-center">
              {user != null && user.user_id === comment.author.id ? (
                <CommentToolTip
                  postID={postID}
                  comment={comment}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  setSeeMore={setSeeMore}
                />
              ) : (
                <div className="w-4 h-4 sm:w-6 sm:h-6"></div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div
      onMouseLeave={() => {
        setShowLikers(false);
      }}
    >
      <div className="flex flex-row gap-1 mb-2 text-[rgb(120,120,120)] items-center relative">
        <div className="text-[16px] items-start sm:text-base flex flex-row gap-4 text-left"
          onMouseEnter={() => {
            setShowLikers(true);
          }}
        >
          {(reactions.length > 0) && 
          <div className="flex items-center gap-2">
              <ReactionComponent
                reactionData={reacters}
                reactions={reactions}
              />
            <span
              style={{
                paddingLeft: `${reactions.length * 3}px`, 
              }}
            >
              {reacters?.length}
            </span>
          </div>}

        </div>
        {(numComments > 0 && reactions?.length > 0) && <div>
          <LuDot />
        </div>}
        {(numComments > 0) &&
        <div className="text-[16px] sm:text-base">
          {numComments} comments
        </div>}
      </div>
      <div className="border-t-[1px] sm:border-b-[1px] border-[#0FEFFD] flex flex-row justify-between gap-4 items-center"
        onMouseEnter={() => {
          setShowLikers(false);
        }}
      >
        <div className="flex-1 flex justify-center items-center hover:bg-[#3C3C3C] py-2 rounded-lg">
          {/*           
          <button className="flex flex-row gap-2 items-center" onClick={handleTogglePostLike}>
            {(isDuplicate(likes, user?.user_id)) ? <FaHeart size={20} /> : <FaRegHeart size={20} />}
            <p className="text-[12px] sm:text-sm">GGWP</p>
          </button>
        */}
        {
          <ReactionButton
            user={currUserInfo}
            reacters={reacters}
            handleTogglePostLike={handleTogglePostLike}
            setReacters={setReacters}
            setReactions={setReactions}
            postID={postID}
          />
        }
        </div>
        <button className="flex-1 flex justify-center items-center hover:bg-[#3C3C3C] py-2 rounded-lg px-1 sm:px-0"
          onClick={handleToggleCreateComment}
        >
          <div className="flex flex-row gap-2 items-center">
            <BiCommentDetail size={20} />
            <p className="text-[12px] sm:text-sm">Comment</p>
          </div>
        </button>
        <button className="flex-1 flex justify-center items-center hover:bg-[#3C3C3C] py-2 rounded-lg"
          onClick={handleSharePost}
        >
          <div className="flex flex-row gap-2 items-center">
            <BsShare size={20} />
            {copied ? <p className="text-[12px] sm:text-sm">Copied!</p> : <p className="text-[12px] sm:text-sm">Share</p>}
          </div>
        </button>
      </div>
      <div className="flex flex-col mt-2 gap-3"
        onMouseEnter={() => {
          setShowLikers(false);
        }}
      >
        {!seeMore && (topComments).map((comment) => renderComment(comment))}
        {seeMore && (comments).map((comment) => renderComment(comment))}
      </div>
      {(!seeMore && numComments > 3) && 
      <a className="hover:cursor-pointer text-[10px] p-2"
        onClick={handleFetchComments}
      >
        View all {numComments} comments 
      </a>}
      {(showCreateComment) &&
      <div className="flex flex-row sm:p-2 gap-2 items-start">
        <div className="h-6 w-6 sm:h-8 sm:w-8 overflow-hidden rounded-full">
          {(currUserInfo?.profile_pic?.length > 0)
            ? <img src={currUserInfo?.profile_pic} className="rounded-full h-full w-full object-cover" />
          : <img src={AnonymousAvatar} className="rounded-full h-full w-full object-cover" />}
        </div>
        <div className="flex flex-col gap-1 text-left mr-auto w-full flex-1 outline-none">
          <p className="text-sm font-medium">{currUserInfo?.username}</p>
          <MentionsInput
            value={newComment}
            placeholder="Enter your comment here"
            onChange={handleChangeCommentBody}
            onPaste={handlePasteMedia}
            className="mentionWrapper"
            style={{
              "input": {
                overflow: "scroll"
              },
              "highlighter": {
                overflow: "hidden",
              },
            }}
            singleLine={false}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
              if (width >= 640 && e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleCreateComment(e);
              }
            }}
          >
            <Mention
              trigger="@"
              data={mentions}
              displayTransform={(id: number | string, display: string) => {
                if (!emojiIDs.includes(String(id))) {
                  return `@${display}`;
                }
                return display;
              }}
              style={MentionsStyle}
              markup="@[__display__](__id__)"
            />
            <Mention
              trigger={":"}
              data={emojis}
              displayTransform={(id: number | string, display: string) => {
                if (emojiIDs.includes(String(id))) {
                  return mapToEmoji(display);
                }
                return display;
              }}
              style={EmojisStyle}
              appendSpaceOnAdd={true}
              markup="[__display__]"
            />
          </MentionsInput>
          {(previewUrl?.length > 0) && <CommentMedia previewUrl={previewUrl} />}
          <div className="flex flex-col gap-1 sm:pt-2">
            <div className="flex flex-row gap-2 relative">
              <button
                className="bg-lavendar-mint rounded-full text-black sm:px-3 sm:py-1 mt-1 text-[14px] sm:text-sm mr-auto px-2 py-0.5"
                onClick={handleCreateComment}
              >
                Comment
              </button>
              {(creating) && <span className="absolute animate-spin h-7 left-28 top-1">
                <AiOutlineLoading size={26} />
              </span>}
            </div>
            {(showCommentErr) && <div className="text-red-500 text-[12px] ml-2">
              * Your comment can't be empty
            </div>}
          </div>
          
      {
      (youtubeVideoLink && youtubeVideoLink?.length > 0) && 
      <span
        className="flex flex-col sm:flex-row sm:gap-4 sm:my-4 bg-black rounded-3xl p-2 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
      >
        <div>
          <img src={youtubeInfo["thumbnail_url"]} alt="" className="h-8 w-10 sm:h-12 sm:rounded-xl pr-2"/>
        </div>
        <div className="flex flex-col">
          <p className="font-sans text-[10px] sm:text-[16px] text-[#FFFFFF]">{youtubeInfo["video_title"]}</p>
          <p className="font-sans text-[8px] sm:text-[12px] text-white]">Channel: {youtubeInfo["channel_title"]}</p>
        </div>
        <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" alt="" className="ml-auto h-6 w-8 sm:h-12" />
      </span>
      }
      {(twitchClipID && twitchClipID?.length > 0) &&
        <span 
          className="flex flex-col sm:flex-row sm:gap-4 sm:my-2 bg-black rounded-3xl p-2 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            window.open(twitchInfo["url"]);
          }}
        >
          <div>
            <img src={twitchInfo["thumbnail_url"]} alt="" className="h-8 w-10 sm:h-12 sm:rounded-xl"/>
          </div>
          <div className="flex flex-col">
            <p className="font-sans text-[10px] sm:text-[16px] text-[#9146FF]">{twitchInfo["title"]}</p>
            <p className="font-sans text-[8px] sm:text-[12px] text-white]">Streamer: {twitchInfo["broadcaster_name"]}</p>
          </div>
          <img src="https://cdn-icons-png.flaticon.com/512/5968/5968819.png" alt="" className="ml-auto h-8 w-8 sm:h-12" />
        </span>
      }


        </div>
        {(showCreateComment && currUserInfo == null) && <span className="items-center flex flex-row gap-2">
          <p className="underline hover:cursor-pointer text-hiloLoginBorder">Login</p>
          <p>to create a comment</p>
        </span>}
      </div>}
    </div>
  )
}

export default Comments;