import React, { useEffect, useState } from "react";
import axios from "axios";

import { isDuplicate } from "../utils";

import { MultiSelectGameList } from "./GameList";
import { Game } from "../types/Game";

interface GameSelectorProps {
  games: Game[];
  setGames: React.Dispatch<React.SetStateAction<Game[]>>;
  selectedGames: Game[];
  setSelectedGames: React.Dispatch<React.SetStateAction<Game[]>>;
  newGames: Game[];
  setNewGames: React.Dispatch<React.SetStateAction<Game[]>>;
}

const GameSelector = ({ games, setGames, selectedGames, setSelectedGames,
  newGames, setNewGames
 }: GameSelectorProps) => {
  const [renderKey, setRenderKey] = useState(1);
  useEffect(() => {
    axios.get("/games/")
    .then((response) => {
      setGames(response.data);
    })
  }, []);

  useEffect(() => {
    setRenderKey((prev) => prev + 1);
  }, [selectedGames, newGames, setSelectedGames, setNewGames]);

  const handleToggleGame = (game: Game) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedGames.includes(game)) {
      setSelectedGames(selectedGames.filter(item => item.id !== game.id));
    } else {
      setSelectedGames([...selectedGames, game]);
    }
    if (!isDuplicate(newGames, game)) {
      setNewGames([...newGames, game]);
    } else {
      setNewGames(newGames.filter(item => item.id !== game.id));
    }
  }

  const isGameSelected = (game: Game) => {
    for (let i = 0; i < newGames.length; i++) {
      if (newGames[i].id === game.id)
        return true;
    }
    return false;
  }

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <h1 className="text-2xl sm:text-4xl italic text-white">Choose Your Top Games</h1>
      <MultiSelectGameList games={newGames} setSelectedGames={setNewGames} showDelete={true} />
      <div className="grid grid-rows-1 mb-8">
        {(renderKey) && <div className="grid grid-cols-3 sm:grid-cols-6 row-span-1 gap-2 sm:gap-4 grow">
          {/* We only want at most half of the games array on top row */}
          {(games.slice(0, Math.min(games.length, 6))).map((game) => {
            if (isGameSelected(game)) {
              return (
                <div className="flex flex-col gap-1 text-center">
                  <button onClick={handleToggleGame(game)} className="h-16 w-16 sm:h-28 sm:w-28">
                    <div className="grow basis-1 h-full object-contain col-span-1 p-2 m-0 border-[1px] border-hiloLoginBorder rounded-lg bg-gray-400 hover:cursor-pointer overflow-hidden">
                      <img src={game.icon_url} alt={game.title} className="w-full h-full object-contain" />
                    </div>
                  </button>
                  <div className="text-[10px] sm:text-[12px] text-white font-poppins">
                    {game.title}
                  </div>
                </div>
              )
            } else {
              return (
                <div className="flex flex-col gap-1 text-center">
                  <button onClick={handleToggleGame(game)} className="h-16 w-16 sm:h-28 sm:w-28">
                    <div className="grow basis-1 h-full object-contain col-span-1 p-2 m-0 border-[1px] border-hiloLoginBorder rounded-lg hover:bg-gray-400 hover:cursor-pointer overflow-hidden">
                      <img src={game.icon_url} alt={game.title} className="w-full h-full object-contain" />
                    </div>
                  </button>
                  <div className="text-[10px] sm:text-[12px] text-white font-poppins">
                    {game.title}
                  </div>
                </div>
              );
            }
          })}
        </div>}
      </div>
    </div>
  )
}

export default GameSelector;