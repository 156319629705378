import React, { useContext, useEffect, useState } from "react";

import MainLayout from "../layout/MainLayout";
import AuthContext from "../components/AuthProvider";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";

import { User, SuggestedUser } from "../types/User";
import { FriendRequest } from "../types/Friend";
import { IconContext } from "react-icons";
import { getAuthHeader } from "../utils";


const Friends = () => {
  const {user} = useContext(AuthContext);
  const [friends, setFriends] = useState<User[]>([]);
  const [suggested, setSuggested] = useState<SuggestedUser[]>([]);
  const [suggestStatus, setSuggestStatus] = useState<Record<number, string>>({});
  const [steamFriends, setSteamFriends] = useState<SuggestedUser[]>([]);
  const [friendReqs, setFriendReqs] = useState<FriendRequest[]>([]);
  const [searchResults, setSearchResults] = useState<SuggestedUser[]>([]);
  const [suggestSteamStatus, setSuggestSteamStatus] = useState<Record<number, string>>({});
  const [viewType, setViewType] = useState("current_friends");
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = (window.screen.width < 600);

  useEffect(() => {
    if (user != null) {
      axios.get(`/friends/?user_id=${user.user_id}`)
      .then((response) => {
        setFriends(response.data["friends"]);
      })
      .catch((err) => {
        alert(`Unable to fetch your friendlist: ${err}`);
      })
    }
  }, [user?.user_id]);

  useEffect(() => {
    if (user != null) {
      axios.get(`/friends/suggested/?user_id=${user.user_id}`)
      .then((response) => {
        setSuggested(response.data);
        let tmp: Record<number, string> = {};
        for (let i = 0; i < response.data.length; i++) {
          tmp[response.data[i].id] = "Add Friend";
        }
        setSuggestStatus({
          ...suggestStatus,
          ...tmp
        });
      })
      .catch((err) => {
        alert(`Unable to fetch suggested friends: ${err}`);
      })
    }
  }, [user?.user_id]);

  useEffect(() => {
    if (user != null) {
      axios.get(`/friends/steam_friends/?user_id=${user.user_id}`)
      .then((response) => {
        setSteamFriends(response.data);
        let tmp: Record<number, string> = {};
        for (let i = 0; i < response.data.length; i++) {
          tmp[response.data[i].id] = "Add Friend";
        }
        setSuggestSteamStatus({
          ...suggestStatus,
          ...tmp
        });
      })
    }
  }, [user?.user_id]);

  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}/friend_requests`, getAuthHeader())
      .then((response) => {
        setFriendReqs(response.data);
      })
      .catch((err) => {
        alert(`Unable to fetch friend requests: ${err}`)
      })
    }
  }, [user?.user_id])

  const renderSuggestedFriend = (newUser: SuggestedUser, useSteam=false) => {
    return (
      <tr className="text-white w-full rounded-md">
        <td className="flex flex-row gap-2 items-center p-2 hover:bg-gray-600 w-full">
          <div className="w-14 h-14 overflow-hidden rounded-full hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              window.location.href=`/profile/${newUser.username}`
            }}
          >
            <img src={newUser.profile_pic} className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <div className="flex flex-row gap-2">
              <a className="text-[15px] sm:text-[12px] xl:text-[15px]"
                href={`/profile/${newUser.username}`}
              >
                {newUser.username}
              </a>
              <button
                className="text-[black] px-2 py-0.5 text-[11px] hover:cursor-pointer bg-gradient-to-r from-[#A2ECE4] via-[#BA99FB] to-[#CD6CFF] hover:text-white rounded-lg ml-auto"
                onClick={handleSendFriendRequest(newUser)}
              >
                {(useSteam) ? suggestSteamStatus[Number(newUser.id)] : suggestStatus[Number(newUser.id)]}
              </button>
            </div>
            <span className="text-white text-wrap">
              <p className="text-[10px] sm:text-[9px] xl:text-[11px]">
                plays:{' '}
                {newUser.games_played.slice(0, 3).join(", ")}
                {(newUser.games_played.length > 3 ? ` and ${newUser.games_played.length - 3} more` : "")}
              </p>
            </span>
          </div>
        </td>
      </tr>
    )
  }

  const handleOpenFriendProfile = (friend: User) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    window.location.href = `/profile/${friend.username}`;
  }

  const handleSendFriendRequest = (newFriend: SuggestedUser) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (suggestStatus[Number(newFriend.id)] === "Request Sent!" || suggestSteamStatus[Number(newFriend.id)] === "Request Sent!") {
      return;
    }

    if (user != null) {
      axios.post(`/users/${newFriend.id}/friend_requests/`, {
        sender_id: user.user_id
      })
      .then((response) => {
        setSuggestStatus({
          ...suggestStatus,
          [Number(newFriend.id)]: "Request Sent!"
        })
        setSuggestSteamStatus({
          ...suggestSteamStatus,
          [Number(newFriend.id)]: "Request Sent!"
        })
      })
    } else {
      alert (`An error occurred while trying to send your friend request`);
      return;
    }
  }

  const handleRemoveFriend = (friend: User) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!window.confirm(`Are you sure you want to unfriend ${friend.username}?`))
      return;

    if (user != null) {
      axios.patch(`/users/${friend.id}/friend_list/`, {
        sender_id: user.user_id
      }, getAuthHeader())
      .then((response) => {
        setFriends(friends.filter(currFriend => currFriend.id !== friend.id));
      })
      .catch((err) => {
        alert(`Unable to remove friend: ${err}`);
      })
    }
  }

  const handleProcessFriendRequest = (req: FriendRequest, processType: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (processType !== "accept" && processType !== "decline")
      return;
    if (user == null || user == undefined)
      return;
  
    axios.patch(`/users/${user.user_id}/friend_requests/?action=${processType}`, {
      "friend_request_id": req.id
    })
    .then((response) => {
      setFriendReqs(friendReqs.filter(currReq => currReq.id !== req.id));
    })
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setSearchResults([]);
      setSearchQuery(e.target.value);
      return;
    }
    setSearchQuery(e.target.value);
    axios.get(`/friends/search/?query=${e.target.value}`, getAuthHeader())
    .then((response) => {
      setSearchResults(response.data);
    });
  }

  return (
    <MainLayout>
      <div className="flex flex-col w-full text-white justify-center item-center px-4 sm:px-12 gap-4 py-2 sm:py-8">
        <h1 className="text-[20px] sm:text-[26px] text-white">Friends</h1>
        <div className="flex sm:flex-row flex-col gap-4 items-start">
          <div className="flex sm:flex-col flex-row gap-1 max-w-full w-full sm:w-1/4 mx-auto sm:mx-0">
            <button className={"text-white flex-1 text-[12px] sm:text-[14px] xl:text-[20px] text-left p-2 hover:bg-gray-500" + (viewType === "search" ? " bg-gray-500" : "")}
              onClick={(e) => {
                e.preventDefault();
                setViewType("search");
              }}
            >
              Search
            </button>
            <button className={"text-white flex-1 text-[12px] sm:text-[14px] xl:text-[20px] text-left p-2 hover:bg-gray-500" + (viewType === "current_friends" ? " bg-gray-500" : "")}
              onClick={(e) => {
                e.preventDefault();
                setViewType("current_friends");
              }}
            >
              Current Friends
            </button>
            <button className={"text-white flex-1 text-[12px] sm:text-[14px] xl:text-[20px] text-left p-2 hover:bg-gray-500" + (viewType === "friend_requests" ? " bg-gray-500" : "")}
              onClick={(e) => {
                e.preventDefault();
                setViewType("friend_requests");
              }}
            >
              Pending Requests
            </button>
            {(isMobile) && <button className={"text-white flex-1 text-[12px] sm:text-[14px] xl:text-[20px] text-left p-2 hover:bg-gray-500" + (viewType === "suggested" ? " bg-gray-500" : "")}
              onClick={(e) => {
                e.preventDefault();
                setViewType("suggested");
              }}
            >
              Suggested
            </button>}
          </div>
          {(viewType === "current_friends") &&
          <>
            {(friends.length > 0) ? <table className="max-w-full w-full sm:w-1/2 border-spacing-0 mb-auto">
              {(friends).map((friend) => {
                return (
                  <tr className="text-white border-spacing-0 m-0 p-0">
                    <td className="flex flex-row gap-2 items-center hover:bg-gray-500 pr-2">
                      <div className="flex flex-row gap-2 sm:gap-4 hover:cursor-pointer mr-auto w-full p-2 items-center"
                        onClick={handleOpenFriendProfile(friend)}
                      >
                        <div className="w-14 h-14 sm:w-16 sm:h-16 xl:w-20 xl:h-20 overflow-hidden rounded-full">
                          <img src={friend.profile_pic} className="w-full h-full object-cover" />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                          <p className="text-[15px] sm:text-[18px] xl:text-[22px]">
                            {friend.username}
                          </p>
                          <p className="text-[11px] sm:text-[13px] xl:text-[16px] font-light italic">
                            {friend.bio}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={handleRemoveFriend(friend)}
                      >
                        <IconContext.Provider value={{ color: "white"}}>
                          <RxCross1 size={24} />
                        </IconContext.Provider>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table> : <h4 className="text-white text-[12px] sm:text-[16px] max-w-full w-full sm:w-1/2">Your added friends will appear here!</h4>}
          </>}
          {(viewType === "friend_requests") &&
          <>
            {(friendReqs.length > 0) ? <table className="max-w-full w-full sm:w-1/2 border-spacing-0 mb-auto">
              {(friendReqs).map((req) => {
                return (
                  <tr className="text-white border-spacing-0 m-0 p-0">
                    <td className="flex flex-row gap-2 items-center hover:bg-gray-500 pr-2">
                      <div className="flex flex-row gap-2 sm:gap-4 hover:cursor-pointer mr-auto w-full p-2 items-center"
                        onClick={handleOpenFriendProfile(req.sender)}
                      >
                        <div className="w-14 h-14 sm:w-16 sm:h-16 xl:w-20 xl:h-20 overflow-hidden rounded-full">
                          <img src={req.sender.profile_pic} className="w-full h-full object-cover" />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                          <p className="text-[15px] sm:text-[18px] xl:text-[22px]">
                            {req.sender.username}
                          </p>
                          <p className="text-[9px] sm:text-[13px] xl:text-[16px] font-light italic">
                            {req.sender.bio}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row gap-2 sm:gap-4 ml-auto">
                        <button
                          className="text-[black] px-2 py-0.5 text-[10px] sm:text-[13px] hover:cursor-pointer bg-gradient-to-r from-[#A2ECE4] via-[#BA99FB] to-[#CD6CFF] hover:text-white rounded-lg ml-auto"
                          onClick={handleProcessFriendRequest(req, "accept")}
                        >
                          Accept
                        </button>
                        <button
                          className="text-[black] bg-[#9c9c9c] px-2 py-0.5 text-[10px] sm:text-[13px] hover:cursor-pointer rounded-lg hover:text-white"
                          onClick={handleProcessFriendRequest(req, "decline")}
                        >
                          Decline
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </table> : <h4 className="text-white text-[12px] sm:text-[16px] max-w-full w-1/2">No pending friend requests!</h4>}
          </>}
          {(viewType === "search") &&
          <div className="flex flex-col w-full sm:w-1/2 gap-4 flex-1">
            <input type="text" value={searchQuery} onChange={handleSearch} className="text-black w-full sm:w-1/2" />
            {(searchResults.length > 0) ? <table className="max-w-full w-full sm:w-auto">
              {(searchResults).map((searchUser: SuggestedUser) => {
                return (
                  <tr className="text-white border-spacing-0 m-0 p-0">
                    <td className="flex flex-row gap-2 items-center hover:bg-gray-500 pr-2">
                      <div className="flex flex-row gap-2 sm:gap-4 hover:cursor-pointer mr-auto w-full p-2 items-center"
                        onClick={(e) => {
                          window.location.href = `/profile/${searchUser.username}`;
                        }}
                      >
                        <div className="w-14 h-14 sm:w-16 sm:h-16 xl:w-20 xl:h-20 overflow-hidden rounded-full">
                          <img src={searchUser.profile_pic} className="w-full h-full object-cover" />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                          <p className="text-[15px] sm:text-[18px] xl:text-[22px]">
                            {searchUser.username}
                          </p>
                          <p className="text-[11px] sm:text-[13px] xl:text-[16px] font-light italic">
                            {searchUser.bio}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table> : <h4 className="text-white text-[12px] sm:text-[16px] max-w-full w-full sm:w-1/2">No results</h4>}
          </div>}
          {(viewType === "suggested") &&
          <>
            <table className="max-w-full w-full sm:w-auto">
              {(suggested).map((newUser: SuggestedUser) => renderSuggestedFriend(newUser))}
            </table>
            {(steamFriends.length > 0) && 
            <>
              <h5 className="text-white text-[14px] xl:text-[22px]">Your friends from Steam</h5>
              <table className="max-w-full w-full sm:w-auto">
                {(steamFriends).map((steamUser: SuggestedUser) => renderSuggestedFriend(steamUser, true))}
              </table>
            </>}
          </>}
          {(!isMobile && viewType !== "search") && <div className="flex flex-col gap-3 max-w-full w-1/4">
            {(steamFriends.length > 0) && 
            <>
              <h5 className="text-white text-[16px] xl:text-[22px]">Your friends from Steam</h5>
              <table className="max-w-full">
                {(steamFriends).map((steamUser: SuggestedUser) => renderSuggestedFriend(steamUser, true))}
              </table>
            </>}
            <h5 className="text-white text-[16px] xl:text-[22px]">Suggested</h5>
            <table className="max-w-full">
              {(suggested).map((newUser: SuggestedUser) => renderSuggestedFriend(newUser))}
            </table>
          </div>}
        </div>
      </div>
    </MainLayout>
  )
}

export default Friends;