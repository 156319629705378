import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthHeader } from '../utils';
import { X } from 'lucide-react';

import hardSupportIcon from "../assets/hard_support.png";
import softSupportIcon from "../assets/soft_support.png";
import offLaneIcon from "../assets/off_lane.png";
import midLaneIcon from "../assets/mid_lane.png";
import safeLaneIcon from "../assets/safe_lane.png";
import sleepingMascot from "../assets/sleeping_mascot.png";

const ChallengeHistory = ({ onClose }) => {
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedChallenge, setExpandedChallenge] = useState(null);

  useEffect(() => {
    axios.get('/challenge/get_history', getAuthHeader())
      .then((response) => {
        setHistory(response.data);
      });
  }, []);

  const getPositionIcon = (position) => {
    switch (position) {
      case 1:
        return safeLaneIcon;
      case 2:
        return midLaneIcon;
      case 3:
        return offLaneIcon;
      case 4:
        return softSupportIcon;
      case 5:
        return hardSupportIcon;
      default:
        return "";
    }
  };

  const handleNext = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const renderChallenge = (challenge) => {
    const completedMatches = challenge.info.matches.filter(match => match.complete).length;
    const isExpanded = expandedChallenge === challenge.id;

    return (
      <div 
        className="bg-black rounded-xl border-2 border-purple-500 p-4 cursor-pointer max-w-xl w-full"
        onClick={() => setExpandedChallenge(isExpanded ? null : challenge.id)}
      >
        <div className="text-xs sm:text-sm font-light mb-1 sm:mb-2 text-gray-400 flex items-center space-x-1 sm:space-x-2">
          <span>{challenge.position_title}</span>
          {challenge.positions.map((pos, index) => (
            <img
              key={index}
              src={getPositionIcon(pos)}
              alt={`Position ${pos}`}
              className="w-4 h-4 sm:w-6 sm:h-6"
            />
          ))}
        </div>

        <div className="text-sm sm:text-lg font-bold mb-1 sm:mb-2 text-white">{challenge.title}</div>
        <div className="text-xs sm:text-sm font-light mb-3 sm:mb-6 text-gray-400">{challenge.subtitle}</div>

        <div className="flex justify-between mb-2 sm:mb-4">
          <div className="text-xs sm:text-sm text-gray-400">
            Success Rate:{" "}
            <span className="font-bold text-white">
              {(challenge.info.success_rate * 100).toFixed(2)}%
            </span>
          </div>
          <div className="text-xs sm:text-sm text-gray-400">
            Avg: <span className="font-bold text-white">
              {challenge.info.average_score.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="text-xs sm:text-sm text-gray-400 mb-2 sm:mb-4 text-center">
          {challenge.start_time_formatted} to {challenge.end_time_formatted}
        </div>

        <div className="w-full bg-gray-700 h-2 rounded mb-1">
          <div 
            className="bg-purple-500 h-full rounded transition-all duration-300"
            style={{ 
              width: `${(completedMatches / challenge.completion_number) * 100}%`,
              maxWidth: '100%'
            }}
          />
        </div>
        <div className="text-right text-xs sm:text-sm text-gray-400">
          {completedMatches}/{challenge.completion_number} games
        </div>

        {isExpanded && (
  <div className="mt-4">
    <div className="max-h-[250px] overflow-y-auto">
      <table className="w-full text-xs sm:text-sm">
        <thead className="sticky top-0 bg-black">
          <tr className="text-gray-400 border-b border-gray-700">
            <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Done</th>
            <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Hero</th>
            <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Pos</th>
            <th className="py-1 sm:py-2 px-2 sm:px-4 text-right">{challenge.stat_title}</th>
          </tr>
        </thead>
        <tbody>
          {challenge.info.matches.map((match, index) => {
            const utmSource = "HiLo";
            const redirectUrl = `https://stratz.com/matches/${match.id}/?utm=${utmSource}`;
            const handleClick = (e) => {
              e.stopPropagation();
              if (match.post_id) {
                window.location.href = `/posts/${match.post_id}`;
              } else {
                window.open(redirectUrl, "_blank");
              }
            };
            return (
              <tr key={index} className="border-b border-gray-600 text-center align-middle">
                <td className="py-1 sm:py-2 px-2 sm:px-4 text-left">
                  {match.complete ? (
                    <div className="flex justify-center items-center w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-green-500 mx-auto">
                      <svg className="w-3 h-3 sm:w-4 sm:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-red-500 mx-auto">
                      <svg className="w-3 h-3 sm:w-4 sm:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  )}
                </td>
                <td className="py-1 sm:py-2 px-2 sm:px-4">
                  <div onClick={handleClick} className="cursor-pointer">
                    <img
                      src={match.hero}
                      alt="Hero"
                      className="w-6 h-6 sm:w-10 sm:h-10 object-cover mx-auto"
                    />
                  </div>
                </td>
                <td className="py-1 sm:py-2 px-2 sm:px-4">
                  <img
                    src={getPositionIcon(match.position)}
                    alt="Position Icon"
                    className="w-5 h-5 sm:w-8 sm:h-8 mx-auto"
                  />
                </td>
                <td className="py-1 sm:py-2 px-2 sm:px-4 text-white text-right">
                      {match.score !== undefined && match.score !== null
                        ? match.score
                        : "Not Parsed"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
)}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative z-10 w-full max-w-2xl mx-auto px-4"> {/* Added mx-auto */}
      <div className="bg-gray-900 rounded-xl p-4 flex flex-col items-center"> {/* Added flex and items-center */}
        <div className="flex justify-between items-center mb-4 w-full">
          <h2 className="text-xl font-bold text-white">Challenge History</h2>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-800 rounded-full transition-colors"
          >
            <X className="text-gray-400 hover:text-white" size={24} />
          </button>
        </div>

          {history.length === 0 ? (
            <div className="flex flex-col items-center justify-center text-center py-8">
              <img 
                src={sleepingMascot} 
                alt="Sleeping mascot" 
                className="w-32 h-32 mb-4"
              />
              <p className="text-gray-400 text-sm">
                zzz ... no challenges completed yet, gotta start cooking champ!
              </p>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb-4">
                <button 
                  className="text-gray-400 hover:text-white disabled:opacity-50 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
                  disabled={currentIndex === 0}
                  onClick={handlePrevious}
                >
                  Previous
                </button>
                <span className="text-white">
                  {currentIndex + 1} / {history.length}
                </span>
                <button 
                  className="text-gray-400 hover:text-white disabled:opacity-50 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
                  disabled={currentIndex === history.length - 1}
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>

              {renderChallenge(history[currentIndex])}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeHistory;