import React, { createContext, useState, useEffect } from 'react'
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useNavigate } from 'react-router-dom'

import { UserJWT } from '../types/User';

interface AuthTokens {
  access: string;
  refresh: string;
}

interface AuthContextType {
  user: UserJWT | null;
  authTokens: AuthTokens | null;
  loginUser: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  logoutUser: () => void;
  handleSteamAuth: (tokens: AuthTokens, userData: any) => Promise<boolean>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

type CustomFormEvent = React.FormEvent<HTMLFormElement> & {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultAuthContext: AuthContextType = {
  user: null,
  authTokens: null,
  loginUser: async () => {},
  logoutUser: () => {},
  handleSteamAuth: async () => false,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export default AuthContext;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    let [authTokens, setAuthTokens] = useState<AuthTokens | null>(() => {
      const tokens = localStorage.getItem('authTokens');
      return tokens && tokens !== "undefined" ? JSON.parse(tokens) : null;
    });
    let [user, setUser] = useState<UserJWT | null>(() => {
      const tokens = localStorage.getItem('authTokens');
      return tokens && tokens !== "undefined" ? jwtDecode<UserJWT>(tokens) : null;
    });
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    let loginUser = async (e: CustomFormEvent) => {
        if (e && typeof e.preventDefault === 'function')
          e.preventDefault();
        const form = e.target as HTMLFormElement;
        const username = (form['username'] as HTMLInputElement).value.toLowerCase();
        const password = (form['password'] as HTMLInputElement).value;

        console.log("requesst body:", {
          method: 'POST',
          headers:{
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(
            {
              'username': username.toLowerCase(),
              'password': password
            }
          )
      });
        
        let response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                'username': username.toLowerCase(),
                'password': password
            })
        });
        let data = await response.json();

        if (response.status === 200) {
            // Delete existing tokens if set
            setAuthTokens(null);
            setUser(null);
            localStorage.removeItem('authTokens');
            // Set new ones
            setAuthTokens(data);
            const decoded = jwtDecode<JwtPayload>(data.access) as UserJWT;
            setUser(decoded);
            localStorage.setItem('authTokens', JSON.stringify(data));
            navigate(`/profile/${username.toLowerCase()}`);
        } else {
          if (e["setLoading"]) {
            e["setLoading"](false);
          }
        }
    };


    let handleSteamAuth = async (tokens: AuthTokens, userData = null) => {
      try {
          // Clear any existing auth
          setAuthTokens(null);
          setUser(null);
          localStorage.removeItem('authTokens');

          // Set new tokens
          setAuthTokens(tokens);
          localStorage.setItem('authTokens', JSON.stringify(tokens));

          // Set user data
          const decoded = jwtDecode(tokens.access);
          console.log("decoded is ", decoded); 
          const finalUserData = (userData || decoded) as UserJWT;
          setUser(finalUserData);
          navigate('/'); 

          return true;
      } catch (error) {
          console.error('Error setting Steam auth:', error);
          return false;
      }
  };

    let logoutUser = () => {
        console.log("LOGGING OUT USER"); 
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        // navigate("/login");
    };


    let updateToken = async () => {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh': authTokens?.refresh})
        });

        let data = await response.json();
        
        if (response.status === 200){
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem('authTokens', JSON.stringify(data));
        } else {
            logoutUser();
        }

        if (loading) {
            setLoading(false);
        }
    };

    let contextData = {
      user: user,
      authTokens: authTokens,
      setAuthTokens: setAuthTokens,  // Make sure these are included
      setUser: setUser,              // Make sure these are included
      loginUser: loginUser,
      logoutUser: logoutUser,
      handleSteamAuth: handleSteamAuth,  // Added new Steam auth handler
  };

    useEffect(()=> {
        if (loading) {
            updateToken();
        } 

        let fourMinutes = 1000 * 60 * 4;

        let interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, loading]);

    return(
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
    )
}