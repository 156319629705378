import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { IconContext } from 'react-icons/lib';
import { BsCameraVideo } from "react-icons/bs";
import { AiOutlinePicture } from "react-icons/ai";
import { CgSmileMouthOpen } from "react-icons/cg";
import { AiOutlineTag } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { TbEyeFilled } from "react-icons/tb";

import AnonymousAvatar from "../assets/hilo-avatar.png";
import Dota2Icon from "../assets/dota-2-icon.png";

import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownMenu
} from "./Dropdown";


const sizes = {
  sm: css`
    width: 300px;
  `,
  lg: css`
    width: 800px;
  `,
  xl: css`
    width: 1140px;
  `,
};

interface CardContainerProps {
  size?: 'sm' | 'lg' | 'xl';
}

const CardContainer = styled.div<CardContainerProps>`
  background: linear-gradient(20deg, 
    rgba(35, 35, 35, 1) 0%, 
    rgba(38, 38, 38, 1) 26%, 
    rgba(0, 0, 0, 0.84) 71%, 
    rgba(10, 10, 10, 0.57) 100%
  );
  border-radius: 36px;
  padding: 20px;
  border: 2px solid #5CE1E6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  ${(props) => sizes[props.size || 'lg']}
`;

const CardBody = styled.div`
  margin-bottom: 20px;
  color: #C4C4C4;
  font-family: 'Poppins';
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 0.5px; /* Thickness of the line */
  background-color: #C4C4C4; /* Color of the line */
  margin: 10px 0; /* Space around the line */
`;

const CardFooter = styled.div`
  display: flex;
`;

export const CardTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #FFF;
  background: none;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: 'Poppins';
  opacity: 1;
  
  &:focus {
    color: #FFF;
    background: none;
    outline: 0;
  }

  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }
`;

interface PostCardProps {
  size?: 'sm' | 'lg' | 'xl';
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  cols?: number;
}

const PostCard = ({ size, value, onChange, placeholder, rows, cols }: PostCardProps) => {
  const [show, setShow] = useState(false);

  const toggleDropdown = () => setShow(!show);

  return (
    <CardContainer size={size}>
      <CardBody>
        <div style={{
          display: "flex", flexDirection: "column"
        }}>
          <div
            style={{display: "flex", flexDirection: "row", gap: "5px"}}
          >
            <img src={AnonymousAvatar} style={{borderRadius: "50%", height: "72px", flex: 1}} />
            <div style={{flex: 10, marginRight: "2rem"}}>
              <DropdownContainer style={{marginBottom: "5px"}}>
                <DropdownButton onClick={toggleDropdown}>
                  <span style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "3px"}}>
                    <img src={Dota2Icon} />
                    <div>Dota 2 Community</div>
                    <IoIosArrowDown />
                  </span>
                </DropdownButton>
                <DropdownMenu show={show}>
                  <DropdownItem href="#action1">Dota 2 Community</DropdownItem>
                  <DropdownItem href="#action2">CS2</DropdownItem>
                  <DropdownItem href="#action3">League of Legends</DropdownItem>
                </DropdownMenu>
              </DropdownContainer>
              <CardTextarea
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                rows={rows}
                cols={cols}
              />
            </div>
          </div>
          <div style={{
            color: "#85B6FF",
            fontFamily: "Poppins",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5rem"
          }}>
            <TbEyeFilled size={24} />
            <div>
              Everyone can see
            </div>
          </div>
        </div>
      </CardBody>
      <HorizontalLine />
      <CardFooter>
        <div style={{flex: 1, display: "flex", gap: "1rem", alignItems: "center"}}>
          <a
            style={{"cursor": "pointer"}}
          >
            <IconContext.Provider
              value={{ color: '#85B6FF' }}
            >
              <AiOutlinePicture size={24}/>
            </IconContext.Provider>
          </a>
          <a
            style={{"cursor": "pointer"}}
          >
            <IconContext.Provider
              value={{ color: '#85B6FF' }}
              >
              <BsCameraVideo size={24} />
            </IconContext.Provider>
          </a>
          <a
            style={{"cursor": "pointer"}}
          >
            <IconContext.Provider
              value={{ color: '#85B6FF' }}
            >
              <CgSmileMouthOpen size={24} />
            </IconContext.Provider>
          </a>
          <a
            style={{"cursor": "pointer"}}
          >
            <IconContext.Provider
              value={{ color: '#85B6FF' }}
            >
              <AiOutlineTag size={24} />
            </IconContext.Provider>
          </a>
        </div>
        <div style={{display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <button
            style={{
              borderRadius: "30px",
              background: "#85B6FF",
              color: "#FFFFFF",
              padding: "6px 16px",
              fontSize: "20px",
              border: "none",
              fontFamily: "Poppins",
              cursor: "pointer",
            }}
          >
            Post
          </button>
        </div>
      </CardFooter>
    </CardContainer>
  )
}

export default PostCard;