import React from "react";
import { IconContext } from "react-icons/lib";
import { IoMdCloseCircle } from "react-icons/io";

import { Game } from "../types/Game";

interface MultiSelectGameListProps {
  games: Game[];
  setSelectedGames: React.Dispatch<React.SetStateAction<Game[]>>;
  showDelete?: boolean;
  setHasMore?: React.Dispatch<React.SetStateAction<boolean>> | null;
  setTrigger?: React.Dispatch<React.SetStateAction<boolean>> | null;
  trigger?: boolean | null;
  pageNum?: number | null;
  setPageNum?: React.Dispatch<React.SetStateAction<number>> | null;
}

export const MultiSelectGameList = ({ games, setSelectedGames, showDelete=false, setHasMore=null, setTrigger=null, trigger=null,
  pageNum=null, setPageNum=null, 
}: MultiSelectGameListProps) => {
  const handleRemoveSelectedGame = (game: Game) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const gameId = game.id;
    setSelectedGames(games.filter(item => item.id !== gameId));
  }

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {(games).map((game: Game) => {
        const isEmptyUrl = ((game.icon_url).length === 0) || (game.icon_url === `""`);
        return (
          <div className="relative pt-0 pl-0 sm:pt-2 sm:pl-2">
            <button 
              className="hover:cursor-pointer hover:bg-gray-400 bg-[#484B51] border-[1px] text-white rounded-3xl sm:p-[2px] sm:px-3 py-[4px] px-[6px]"
              onClick={(e) => {
                e.preventDefault();
                setSelectedGames([...games, game]);
                if (setHasMore != null && setTrigger != null && setPageNum != null) {
                  setTrigger(!trigger);
                  setHasMore(true);
                  setPageNum(0);
                }
              }}
            >
              <span className="flex flex-row gap-1 sm:gap-1 items-center">
                {(!isEmptyUrl) ? (
                  <img src={game.icon_url} className="h-4"/>
                ) : null}
                <p className="text-[12px] sm:text-[14px] sm:text-base font-poppins px-1">{game.title}</p>
              </span>
            </button>
            {(showDelete) && 
            <button className="absolute top-0 left-0 z-10"
              onClick={handleRemoveSelectedGame(game)}
            >
              <span>
                <IconContext.Provider value={{ color: 'white' }}>
                  <IoMdCloseCircle size={32}
                    onMouseOver={({target}) => {
                      (target as HTMLElement).style.color = "#D7FBFF"
                    }}
                    onMouseOut={({target}) => {
                      (target as HTMLElement).style.color = "white"
                    }}
                  />
                </IconContext.Provider>
              </span>
            </button>}
          </div>
        )
      })}
    </div>
  )
}

interface SingleSelectGameListProps {
  games: Game[];
  setSelectedGame: React.Dispatch<React.SetStateAction<Game | null>>;
  showDelete?: boolean;
  setHasMore?: React.Dispatch<React.SetStateAction<boolean>> | null;
  setTrigger?: React.Dispatch<React.SetStateAction<boolean>> | null;
  trigger?: boolean | null;
  pageNum?: number | null;
  setPageNum?: React.Dispatch<React.SetStateAction<number>> | null;
}

export const SingleSelectGameList = ({ games, setSelectedGame, setHasMore=null, setTrigger=null, trigger=null,
  pageNum=null, setPageNum=null, 
}: SingleSelectGameListProps) => {

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {(games).map((game: Game) => {
        const isEmptyUrl = ((game.icon_url).length === 0) || (game.icon_url === `""`);
        return (
          <div className="relative pt-0 pl-0 sm:pt-2 sm:pl-2">
            <button 
              className="hover:cursor-pointer hover:bg-gray-400 bg-[#484B51] border-[1px] text-white rounded-3xl sm:p-[2px] sm:px-3 py-[4px] px-[6px]"
              onClick={(e) => {
                e.preventDefault();
                setSelectedGame(game);
                if (setHasMore != null && setTrigger != null && setPageNum != null) {
                  setTrigger(!trigger);
                  setHasMore(true);
                  setPageNum(0);
                }
              }}
            >
              <span className="flex flex-row gap-1 sm:gap-1 items-center">
                {(!isEmptyUrl) ? (
                  <img src={game.icon_url} className="h-4"/>
                ) : null}
                <p className="text-[12px] sm:text-[14px] sm:text-base font-poppins px-1">{game.title}</p>
              </span>
            </button>
          </div>
        )
      })}
    </div>
  )
}