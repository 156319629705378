import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { IconContext } from "react-icons/lib";
import { RxCross1 } from "react-icons/rx";

import { AiOutlineLoading } from "react-icons/ai";
import PlaceholderPhoto from "../assets/add-photo.png";

import { User, UserJWT } from "../types/User";
import { WidgetInfo } from "../types/Widget";

interface WidgetModalProps {
  user: UserJWT | null;
  userInfo: User | null;
  selectedGame: any;
  widgetInfo: WidgetInfo;
  setWidgetInfo: Dispatch<SetStateAction<WidgetInfo>>;
  setCreated: Dispatch<SetStateAction<boolean>>;
  created: boolean;
  handleSaveNewWidget: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  removing: boolean;
  setRemoving: Dispatch<SetStateAction<boolean>>;
  handleRemoveWidgets: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const WidgetModal = ({ user, userInfo, selectedGame, widgetInfo, setWidgetInfo,
  setCreated, created, handleSaveNewWidget, loading, setLoading, removing,
  setRemoving, handleRemoveWidgets
}: WidgetModalProps) => {
  const [open, setOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(PlaceholderPhoto);
  const [showImgErr, setShowImgErr] = useState(false);
  const [selectedPic, setSelectedPic] = useState<File | null>(null);
  const [descErr, setDescErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  }
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCreated(false);
    setLoading(false);
    setOpen(false);
    setShowImgErr(false);
    setSelectedPic(null);
    setPreviewUrl(PlaceholderPhoto);
    // reset widget info vals
    setWidgetInfo({
      "description": "",
      "title": "",
      "selected_pic": null,
      "preview_url": "",
      "widget_type": "custom_create",
    });
  }

  const handleUploadChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (!file || !file.type.startsWith('image/') || file.size > maxFileSize) {
      setShowImgErr(true);
      return;
    } else {
      setShowImgErr(false);
    }

    setSelectedPic(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setPreviewUrl(reader.result);
      }
    }
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWidgetInfo({...widgetInfo, [field]: e.target.value});
  }

  const handleSaveWidgetInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let fail = false;
    if (widgetInfo["title"]?.length == 0 || widgetInfo["title"]?.length > 15) {
      setTitleErr(true);
      fail = true;
    } else
      setTitleErr(false);

    if (widgetInfo["description"]?.length == 0 || widgetInfo["description"]?.length > 15) {
      setDescErr(true);
      fail = true;
    } else
      setDescErr(false);

    if (fail)
      return;

    // also set selectedPic
    setCreated(true);
    setWidgetInfo({...widgetInfo, "selected_pic": selectedPic, "preview_url": previewUrl});
    // close modal
    setOpen(false);
    setShowImgErr(false);
    setSelectedPic(null);
    setPreviewUrl(PlaceholderPhoto);
  }

  return (
    <div>
      {(user?.user_id === userInfo?.id) && (selectedGame != null) && (!created && !removing) &&
      <div className="flex flex-row gap-2">
        <button
          className="bg-[rgba(255, 255, 255, 0)] text-white border-[1px] border-[#D7FBFF] font-poppins text-[10px] sm:text-[13px] rounded-full p-2 sm:px-4 sm:py-1.5 mt-4"
          onClick={openModal}
        >
          +
        </button>
        <button
          className="bg-[rgba(255, 255, 255, 0)] text-white border-[1px] border-[#D7FBFF] font-poppins text-[10px] sm:text-[13px] rounded-full p-2 sm:px-4 sm:py-1.5 mt-4"
          onClick={(e) => {
            e.preventDefault();
            setRemoving(true);
          }}
        >
          -
        </button>
      </div>}
      {(user?.user_id === userInfo?.id) && (selectedGame != null) && (created || removing) &&
      <div className="flex flex-row gap-4 relative">
        {(created) && <button
          className="bg-[rgba(255, 255, 255, 0)] text-white border-[1px] border-[#D7FBFF] font-poppins text-[10px] sm:text-[13px] rounded-full p-2 sm:px-4 sm:py-1.5 mt-4"
          onClick={handleSaveNewWidget}
        >
          Save
        </button>}
        {(removing) && <button
          className="bg-[rgba(255, 255, 255, 0)] text-white border-[1px] border-[#D7FBFF] font-poppins text-[10px] sm:text-[13px] rounded-full p-2 sm:px-4 sm:py-1.5 mt-4"
          onClick={handleRemoveWidgets}
        >
          Save
        </button>}
        {(loading) && <div className="absolute animate-spin h-8 top-4 left-20">
          <IconContext.Provider value={{"color": "#FFF"}}>
            <AiOutlineLoading size={32} />
          </IconContext.Provider>
        </div>}
      </div>
      }
      {open && (
        <div className="fixed inset-0 bg-white bg-opacity-35 flex items-center justify-center z-50">
          <div className="bg-[#232323] border-[#5CE1E6] border-2 rounded-3xl shadow-lg p-8 w-full max-w-xl relative flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <div className="font-semibold font-poppins text-[24px] text-white">Add widget</div>
            </div>
            <div className="flex sm:flex-row flex-col gap-8">
              <div className="flex flex-col gap-4 order-2 sm:order-1">
                <div className="flex flex-col gap-1">
                  <div className="font-semibold text-[18px] font-poppins text-white">
                    Attribute
                    <span className="text-[#D7FBFF] font-poppins ml-2 text-[12px]">
                      {widgetInfo["title"]?.length}/15
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <textarea 
                      className="resize-none bg-transparent text-white border-[1px]"
                      rows={1}
                      placeholder="top played"
                      value={widgetInfo["title"]}
                      onChange={handleChange("title")}
                    />
                    {(descErr) && 
                    <div className="text-[10px] sm:text-[13px] text-red-500">
                      *Attribute must be between 0-15 characters
                    </div>}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="font-semibold text-[18px] font-poppins text-white">
                    Detail
                    <span className="text-[#D7FBFF] font-poppins ml-2 text-[12px]">
                      {widgetInfo["description"]?.length}/15
                    </span>
                  </div>
                  <div>
                    <textarea
                      className="resize-none bg-transparent text-white border-[1px]"
                      rows={1}
                      placeholder="Kirby"
                      value={widgetInfo["description"]}
                      onChange={handleChange("description")}
                    />
                    {(titleErr) &&
                    <div className="text-[10px] sm:text-[13px] text-red-500">
                      *Description must be between 0-15 characters
                    </div>}
                  </div>
                </div>
                <button
                  className="text-white font-poppins text-[20px] bg-[#D7FBFF] bg-opacity-50 px-14 py-2 rounded-full mr-auto"
                  onClick={handleSaveWidgetInfo}
                >
                  Save
                </button>
              </div>
              <div className="flex-1 order-1 sm:order-2">
                <div className="sm:flex sm:flex-col sm:items-center sm:justify-center text-center" >
                  <label htmlFor="upload-pfp" className="text-center hover:cursor-pointer">
                    <div className="h-48 w-48 mb-4 mx-auto rounded-full overflow-hidden">
                      <img src={previewUrl} alt="" className="w-full h-full object-cover" />
                    </div>
                  </label>
                  {(showImgErr) && 
                    <p className="text-[10px] sm:text-[13px] text-red-500 mx-10">Please upload a valid image that is under 5MB in size</p>
                  }
                  <input type="file" id="upload-pfp" onChange={handleUploadChange} className="text-white hidden justify-self-center"/>
                </div>
              </div>
            </div>
            <button onClick={closeModal} className="absolute top-8 right-8">
              <IconContext.Provider
                value={{"color": "#FFF"}}
              >
                <RxCross1 size={20} />
              </IconContext.Provider>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default WidgetModal;