import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { DropdownContainer, DropdownButton, DropdownMenu, DropdownItem } from "../components/Dropdown";
import { 
  getAuthHeader,
  truncateStr,
} from "../utils";
import Button from "../components/Button";

import Challenge from "../components/Challenge";
import AuthContext from "../components/AuthProvider";
import PostModal from "../components/PostModal";
import InfiniteScroll from "react-infinite-scroll-component";
import MainLayout from "../layout/MainLayout";

import { IoIosArrowDown } from "react-icons/io";

import EditPostModal from "../components/EditPostModal";

import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import VibesIcon from "../assets/vibes-icon.png";
import { IconContext } from "react-icons/lib";

import PostContent from "../components/PostContent";
import { User } from "../types/User";
import { Post } from "../types/Post";
import { Vibe } from "../types/Vibe";
import { Game } from "../types/Game";
import ChallengeHistory from "../components/ChallengeHistory";

import Duel from "../components/Duel";
import DuelWinner from "../components/DuelWinner";

const Feed = () => {
  // Post pagination
  const [showHistory, setShowHistory] = useState(false);

  const [posts, setPosts] = useState<JSX.Element[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [pageNum, setPageNum] = useState(1);
  const [currPost, setCurrPost] = useState<Post | null>(null);
  const [showEdit, setShowEdit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedGame, setSelectedGame] = useState<Game>({
    "id": 0,
    "title": "View all",
    "icon_url": "",
    "can_generate": false,
    "igdb_id": 0,
    "preset_fields": [],
    "preset_placeholders": [],
  });
  const [showDrop, setShowDrop] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const {user} = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState<User>({
    id: 0,
    username: "",
    bio: "",
    steam_id: "",
    profile_pic: "",
    user_timezone: "",
    steam_id64: "",
    discord_username: "",
    discord_id: 0,
    is_superuser: false,
    vibes: 0,
    is_duel_winner: false,
  });
  const [vibes, setVibes] = useState<Vibe[]>([]);
  const [showVibeTip, setShowVibeTip] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [feedType, setFeedType] = useState("everyone");
  const [isOn, setIsOn] = useState(false);
  const [showFullDuel, setShowFullDuel] = useState(false);
  const [userGames, setUserGames] = useState([]);
  const dropRef = useRef<HTMLDivElement>(null);
  const [selectedUserGame, setSelectedUserGame] = useState<Game>({
    id: "",
    title: "",
    icon_url: "",
    can_generate: false,
    igdb_id: 0,
    preset_fields: [],
    preset_placeholders: [],
  });
  const isMobile = (window.screen.width < 600);

  const fetchInitialPosts = () => {
    setPosts([]);
    setPageNum(1);

    if (mentions.length > 0) {
      let url = "";
      if (!isGameNull(selectedGame) && feedType === "everyone") {
        url = `/posts/feed/?page=1&game=${selectedGame?.id}&show=everyone`;
      } else if (!isGameNull(selectedGame) && feedType === "friends_only") {
        url = `/posts/feed?page=1&game=${selectedGame.id}&show=friends_only`;
      } else if (isGameNull(selectedGame) && feedType === "everyone") {
        url = `/posts/feed?page=1&show=everyone`;
      } else {
        url = `/posts/feed?page=1&show=friends_only`;
      }

      axios.get(url, getAuthHeader())
      .then((response) => {
        let morePosts = [];
        for (let i = 0; i < response.data["posts"]?.length; i++) {
          let post = (
            <PostContent
              post={response.data["posts"][i]}
              mentions={mentions}
              handleOpenEditModal={handleOpenEditModal}
              currUserInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              user={user}
            />
          );
          morePosts.push(post);
        }

        if ("next" in response.data) {
          setPageNum(Number(response.data["next"]));
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setPosts(morePosts);
      });
    }
  }

  useEffect(() => {
    if (user != null) {
      // TODO: Refactor later, this is callback hell
      axios.get(`/users/${user.user_id}/`, getAuthHeader())
      .then((response) => {
        setUserInfo(response.data);
      });
    }
  }, []);

  useEffect(() => {
    axios.get("/users", getAuthHeader())
    .then((response) => {
      setMentions(response.data.map((user: User, idx: number) => {
        return {
          "id": idx,
          "display": user?.username
        }
      }));
    });
  }, [])

  useEffect(() => {
    // Reset values in case they are stale
    if (mentions.length > 0 && userInfo && selectedGame) {
      fetchInitialPosts();
    }
  }, [trigger, selectedGame, feedType, mentions, userInfo]);

  useEffect(() => {
    if (user != null) {
      axios.get(`/games/?user_id=${user.user_id}`)
      .then((response) => {
        setUserGames(response.data);
        setSelectedUserGame(response.data[0]);
      });
    }
  }, []);

  useEffect(() => {
    axios.get(`/games/`, getAuthHeader())
    .then((response) => {
      let allGames: Game[] = [
        {
          "id": 0,
          "title": "View all",
          "icon_url": "",
          "can_generate": false,
          "igdb_id": 0,
          "preset_fields": [],
          "preset_placeholders": [],
        },
        ...response.data
      ]; // placeholder id = "0" value for 'view all'
      setGames(allGames);
    });
  }, []);

  const isGameNull = (game: Game) => {
    return (game == null || (game?.id === 0))
  }

  const toggleDropdown = () => setShowDrop(!showDrop);

  const handleCloseDropdown = (e: MouseEvent) => {
    if (showDrop && !dropRef.current?.contains(e.target as Node)) {
      setShowDrop(false);
    }
  }
  document.addEventListener('mousedown', handleCloseDropdown)

  const handleSelectDropdown = (game: Game) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setPageNum(1);
    if (game?.id === 0)
      setSelectedGame({
        "id": 0,
        "title": "View all",
        "icon_url": "",
        "can_generate": false,
        "igdb_id": 0,
        "preset_fields": [],
        "preset_placeholders": [],
      });
    else
      setSelectedGame(game);
    setShowDrop(false);
  }

  const isEmptyUrl = (url: string) => {
    return (url === `""` || url?.length == 0);
  }

  const handleSaveDotaMatchesEndpoint = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios.get("/dota_matches/save_dota_match", getAuthHeader())
  }

  const handleOpenEditModal = (post: Post) => (e: any) => {
    e.preventDefault();
    setCurrPost(post);
    setShowEdit(true);
  }

  const handleFetchPosts = () => {
    let url = "";
    if (!isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed/?page=${pageNum}&game=${selectedGame?.id}&show=everyone`;
    } else if (!isGameNull(selectedGame) && feedType === "friends_only") {
      url = `/posts/feed?page=${pageNum}&game=${selectedGame.id}&show=friends_only`;
    } else if (isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed?page=${pageNum}&show=everyone`;
    } else {
      url = `/posts/feed?page=${pageNum}&show=friends_only`;
    }

    axios.get(url, getAuthHeader())
    .then((response) => {
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={userInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }
      
      // Extend posts array
      setPosts(posts.concat(morePosts));
      // Handle more posts case
      if (!("next" in response.data)) {
        setHasMore(false);
      } else {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      }
    })
  }

  // const handleToggleVibeToolTip = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   setShowVibeTip(!showVibeTip);
  // }


  return (
    <MainLayout userInfo={userInfo}>     
      <div className="flex flex-col w-full">
        {/* Mobile view: Challenges and Duel at the top */}
        {isMobile && (
          <div className="flex flex-col w-full px-3 mt-4">
            <div className="mb-8">
              <h1 className="font-sans text-[22px] text-white mb-2 font-bold">
                CHALLENGES
              </h1>
              <button 
                    onClick={() => setShowHistory(true)} 
                    className="bg-purple-500 hover:bg-purple-700 text-white text-sm py-3 px-4 rounded-lg transition-all duration-200 flex items-center gap-2 border border-purple-400 mb-2"
                    >
                    COMPLETED CHALLENGES
                    <span className="bg-purple-400 rounded-full p-0.5">
                      <svg 
                        width="12" 
                        height="12" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M12 8v4l3 3" />
                        <circle cx="12" cy="12" r="9" />
                      </svg>
                    </span>
                  </button>

              <div className="flex flex-row gap-2 w-full">
                <div className="w-1/2">
                  <Challenge/>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <h1 className="font-sans text-[22px] text-white mb-3 font-bold">
                DUEL
              </h1>
              <div className="sm:flex-none sm:gap-0 gap-4 flex flex-row sm:mx-0">
                <Duel showFullDuel={showFullDuel} setShowFullDuel={setShowFullDuel} />
              </div>
            </div>
          </div>
        )}

        {/* Main content area */}
        <div className="flex flex-row w-full align-middle justify-center">
          {/* Desktop view: Left sidebar with challenges */}
          {!isMobile && (
            <div className="flex flex-col flex-1 my-8 max-w-[30%] items-center max-lg:hidden">
              <aside className="h-screen sticky top-0 overflow-y-scroll w-full pr-3"
                style={{ "scrollbarWidth": "none" }}
              >
                <h1 className="font-sans text-[22px] text-white mb-3 font-bold">
                  CHALLENGES
                </h1>
                <div className="flex items-center gap-2 mb-4">
                  <button 
                    onClick={() => setShowHistory(true)} 
                    className="bg-purple-500 hover:bg-purple-700 text-white text-sm py-3 px-4 rounded-lg transition-all duration-200 flex items-center gap-2 border border-purple-400 mb-2"
                  >
                    COMPLETED CHALLENGES
                    <span className="bg-purple-400 rounded-full p-0.5">
                      <svg 
                        width="12" 
                        height="12" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M12 8v4l3 3" />
                        <circle cx="12" cy="12" r="9" />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="my-4 w-full">
                  <Challenge/>
                </div>
              </aside>
            </div>
          )}

          {/* Main feed content */}
          {(!isMobile || (isMobile && !showFullDuel)) && (
            <div className="flex flex-col flex-1 max-w-full sm:max-w-[45%] mx-3 sm:mx-0 my-4 sm:my-8 max-sm:pr-3">
              <span className={isMobile ? "flex flex-row items-end mb-2" : "flex flex-row items-end mb-2 w-full"}>
                <div className="flex flex-row items-end">
                  <DropdownContainer ref={dropRef}>
                    <DropdownButton onClick={toggleDropdown}>
                      <span className="flex flex-row items-center gap-1">
                        {selectedGame && !isEmptyUrl(selectedGame?.icon_url) && 
                          <img src={selectedGame?.icon_url} className="h-4" />
                        }
                        <div className="pr-1 text-[10px] sm:text-[12px]">{truncateStr(selectedGame?.title, 25)}</div>
                        <IoIosArrowDown />
                      </span>
                    </DropdownButton>
                    <DropdownMenu show={showDrop}>
                      {games.map((game) => (
                        <DropdownItem 
                          onClick={handleSelectDropdown(game)}
                          className="hover:cursor-pointer"
                        >
                          {game.title}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </DropdownContainer>
                  <span className="text-white flex flex-row gap-2 ml-2 items-center">
                    <div className="font-poppins text-[12px] sm:text-[14px]">
                      {feedType === 'everyone' ? "Everyone" : "Friends only"}
                    </div>
                    <button
                      className={`relative inline-flex items-center rounded-full w-12 sm:w-16 h-7 transition-colors ${
                        isOn ? 'bg-[#D99BFF]' : 'bg-gray-200'
                      }`}
                      onClick={() => {
                        if (feedType === 'everyone') setFeedType('friends_only');
                        else setFeedType('everyone');
                        setIsOn(!isOn);
                      }}
                    >
                      <span
                        className={`inline-block w-5 h-5 rounded-full transition-transform transform ${
                          isOn
                            ? 'translate-x-6 sm:translate-x-10 bg-[#262626]'
                            : 'translate-x-1 bg-gray-400'
                        }`}
                      />
                    </button>
                  </span>
                </div>
                <div className="ml-auto">
                  <Button 
                    color="gradient"
                    size={isMobile ? "xs" : "sm"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveDotaMatchesEndpoint(e);
                      setShowPostModal(true);
                    }}
                  >
                    Create a Post
                  </Button>
                </div>
              </span>
              <InfiniteScroll
                dataLength={posts?.length}
                next={handleFetchPosts}
                hasMore={hasMore}
                loader={<h4 className="text-[13px] sm:text-lg italic text-white">Loading...</h4>}
                className="pl-2 w-full"
                scrollThreshold={0.7}
              >
                <ol className="relative sm:mb-48">
                  {posts.map((item) => item)}
                </ol>
              </InfiniteScroll>
              <PostModal
                show={showPostModal}
                onHide={() => setShowPostModal(false)}
                setTrigger={setTrigger}
                trigger={trigger}
                userInfo={userInfo}
                setPageNum={setPageNum}
                mentions={mentions}
                games={userGames}
                selectedGame={selectedUserGame}
                setSelectedGame={setSelectedUserGame}
              />
              {currPost && (
                <EditPostModal
                  show={showEdit}
                  onHide={() => { setShowEdit(false) }}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  postID={currPost?.id}
                  isFeat={currPost?.post_type === "feat"}
                  mentions={mentions}
                />
              )}
            </div>
          )}

          {/* Desktop view: Right sidebar with duel */}
          {!isMobile && (
            <div className="flex flex-col flex-1 my-8 max-w-[30%] items-center max-lg:hidden">
              <aside className="h-screen sticky top-0 overflow-y-scroll w-full pl-6"
                style={{ "scrollbarWidth": "none" }}
              >
                <h1 className="font-sans text-[22px] text-white mb-3 font-bold">
                  DUEL
                </h1>
                <Duel showFullDuel={showFullDuel} setShowFullDuel={setShowFullDuel} />
              </aside>
            </div>
          )}
        </div>
      </div>
    {showHistory && <ChallengeHistory onClose={() => setShowHistory(false)} />}

    </MainLayout>
  );
}

export default Feed;