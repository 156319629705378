import { useState, useRef, useEffect } from "react";
import VideoThumbnail from 'react-video-thumbnail';
import { IoMdTime } from "react-icons/io";
import { GrCircleAlert } from "react-icons/gr";

interface MediaCarouselProps {
  slides: string[];
  videoURL?: string;
  preview?: boolean;
  videoName?: string;
  videoStatus?: string;
  isVideoPreview?: boolean;
}

const MediaCarousel = ({ slides, videoURL="", preview=false, videoName="", videoStatus="no_video", isVideoPreview=false }: MediaCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [alreadyPlayed, setAlreadyPlayed] = useState<boolean>(false);
  const [alreadyEnter, setAlreadyEnter] = useState<boolean>(false);
  const isMobile = window.screen.width < 600;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (videoRef.current && !alreadyPlayed) {
            videoRef.current.play();
            setAlreadyPlayed(true);
          }
        } else {
          if (videoRef.current)
            videoRef.current.pause();
        }
      },
      { threshold: 1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const handleVideoClick = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.preventDefault();
    if (!videoRef.current)
      return;
    if (!videoRef.current?.paused) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }

  const handleDoubleClick = (e: React.MouseEvent<HTMLVideoElement>) => {
    e.preventDefault();
    if (!videoRef.current)
      return;
    
    if (document.fullscreenElement != null) {
      document.exitFullscreen();
    } else {
      videoRef.current.requestFullscreen();
    }
  }

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={"relative w-full mx-auto" + (preview ? "" : " max-w-xl")}>
      {((videoURL?.length === 0 || !isVideoPreview) && videoStatus === "no_video") && 
        <>
          <div className="overflow-hidden">
            {(!preview) ? <div
              className="flex flex-row gap-2 transition-transform duration-500"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides != null &&
                slides.length > 0 &&
                slides.map((slide, index) => {
                  let imgStyle;
                  if (!isMobile) {
                    imgStyle = {
                      backgroundImage: `url(${slide})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "30vh", // Adjust the height as needed
                      backgroundColor: "black", // Ensure the black bars have a background color
                    };
                  } else {
                    imgStyle = {
                      backgroundImage: `url(${slide})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "20vh", // Adjust the height as needed
                      backgroundColor: "black", // Ensure the black bars have a background color
                    };
                  }
                  return (
                    <div key={index} className="min-w-full" onClick={() => openModal(index)}>
                      <div style={imgStyle} className="w-full cursor-pointer" />
                    </div>
                  );
                })}
            </div> : <div
              className="flex flex-row gap-2"
            >
              {slides != null &&
                slides.length > 0 &&
                slides.map((slide, index) => {
                  return (
                    <div key={index} className="w-20 h-14 overflow-hidden rounded-md bg-black" onClick={() => openModal(index)}>
                      <img src={slide} className="w-full h-full cursor-pointer object-contain" />
                    </div>
                  );
                })}
            </div>}
          </div>
          {slides != null && slides.length > 1 && !preview && (
            <>
              <button
                onClick={goToPrevious}
                className="font-sans absolute top-1/2 left-0 transform -translate-y-1/2 text-white px-3 py-1 text-[24px]"
              >
                &#10094;
              </button>
              <button
                onClick={goToNext}
                className="font-sans absolute top-1/2 right-0 transform -translate-y-1/2 text-white px-3 py-1 text-[24px]"
              >
                &#10095;
              </button>
            </>
          )}
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-100 flex justify-center items-center z-50">
              <div className="relative w-full h-full flex justify-center items-center" onClick={closeModal}>
                <img
                  src={slides[currentIndex]}
                  alt="Fullscreen"
                  className="object-contain max-w-full max-h-full"
                />
                <button className="absolute top-14 right-2 sm:top-2 sm:right-2 text-white text-2xl z-50" onClick={closeModal}>
                  &times;
                </button>
              </div>
              {slides.length > 1 && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      goToPrevious();
                    }}
                    className="font-sans absolute left-0 top-1/2 transform -translate-y-1/2 text-white px-4 py-2 text-[24px]"
                  >
                    &#10094;
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      goToNext();
                    }}
                    className="font-sans absolute right-0 top-1/2 transform -translate-y-1/2 text-white px-4 py-2 text-[24px]"
                  >
                    &#10095;
                  </button>
                </>
              )}
            </div>
          )}
        </>
      }
      {(videoStatus === "video_processing") &&
      <span className="flex flex-row gap-2">
        <IoMdTime size={24} />
        <p className="text-[16px] text-white font-light">Video is still processing, may usually take a few minutes! </p>
      </span>
      }
      {(videoStatus === "video_error") &&
      <span className="flex flex-row gap-2">
        <GrCircleAlert size={24} />
        <p className="text-[16px] text-white font-light">Video upload failed or was corrupted</p>
      </span>
      }
      {(videoURL?.length > 0 && isVideoPreview) && 
        <>
          {(!preview) ? 
          <video controls ref={videoRef} onClick={handleVideoClick} onDoubleClick={handleDoubleClick} muted={true}>
            <source src={videoURL} type="video/mp4" />
          </video> :
          <span
            className="flex flex-row items-center gap-4 sm:my-2 bg-black rounded-3xl p-3 sm:p-4 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer w-full"
          >
            <div className="h-9 w-16 sm:h-14 sm:w-24 rounded-md overflow-hidden">
              <VideoThumbnail
                videoUrl={videoURL}
              />
            </div>
            <div className="flex flex-col">
              <p className="font-sans text-[16px] sm:text-[24px] text-white">{videoName}</p>
            </div>
          </span>}
        </>
      }
    </div>
  );
};

export default MediaCarousel;
