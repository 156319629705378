import axios from "axios";
import { emojiMapper } from "./emojis.ts";
import SafeLane from "./assets/safe-lane.png";
import MidLane from "./assets/mid-lane.png";
import OffLane from "./assets/off-lane.png";
import SoftSupport from "./assets/soft-support.png";
import HardSupport from "./assets/hard-support.png";

type Reaction = {
  user_id: number | string;
  profile_pic: string;
  username: string;
  reaction_type: string;
}

/* DOTA 2 specific */ 
export const DOTA2_LANE_ICON_MAP: { "1": any, "2": any, "3": any, "4": any, "5": any} = {
  "1": SafeLane,
  "2": MidLane,
  "3": OffLane,
  "4": SoftSupport,
  "5": HardSupport
}
/******/

export const getAuthHeader = () => {
  const tokens = (() => {
    const item = localStorage.getItem("authTokens");
    return item ? JSON.parse(item) : null;
  })();
  
  const extractUsername = () => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split('/');
    // Check if the last part of the path is a non-empty string
    if (pathParts.length > 2 && pathParts[1] === 'profile' && pathParts[2]) {
      return decodeURIComponent(pathParts[2]);
    }
    return "";
  }

  if (tokens == null || tokens["access"] == null) {
    return {
      "headers": {
        "User": `${extractUsername()}`
      }
    }
  } else {
    return {
      "headers": {
        "Authorization": `Bearer ${tokens["access"]}`,
        "User": `${extractUsername()}`
      }
    }
  }
}

export const clearQueryParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.forEach((value, key) => {
    params.delete(key);
  });

  window.history.replaceState({}, document.title, url.pathname + url.hash);
}

export const mapToEmoji = (str: string) => {
  return emojiMapper[str];
}

export const formatStat = (stat: string | null) => {
  if (stat == null)
    return "";
  let number = parseFloat(stat);

  if (!isNaN(number)) {
    return number.toLocaleString('en-US');
  } else {
    return stat;
  }
}

export const truncateStr = (originalStr: string, maxLen: number) => {
  if (originalStr == null)
    return "";

  if (originalStr.length > maxLen) {
    return originalStr.slice(0, maxLen) + '...';
  } else {
    return originalStr;
  }
}

export const getUniqueReactions = (reactionsArray: Reaction[]): string[]  => {
  const uniqueReactions = [...new Set(reactionsArray.map(item => item.reaction_type))];
  return uniqueReactions;
}

export const isEmptyJSON = (item: Record<string, any>) => {
  // Basically checks if JSON === "{}"
  return (Object.entries(item).length === 0);
}

export const isDuplicate = (array: any[], newItem: any) => {
  return array.some(item => JSON.stringify(item) === JSON.stringify(newItem));
}

export const getTwitchAccessToken = () => {
  const baseUrl = "https://id.twitch.tv/oauth2/token"
  const clientID = `${process.env.REACT_APP_TWITCH_CLIENT_ID}`;
  const clientSecret = `${process.env.REACT_APP_TWITCH_CLIENT_SECRET}`;
  const grantType = "grant_type=client_credentials"

  const accessUrl = `${baseUrl}?client_id=${clientID}&client_secret=${clientSecret}&${grantType}`;

  axios.post(accessUrl)
  .then((response) => {
    alert(`Token: ${response.data.access_token}`);
    return response.data.access_token;
  });
}

export const sameDay = (datetimeStr1: string, datetimeStr2: string) => {
  const date1 = new Date(datetimeStr1);
  const date2 = new Date(datetimeStr2);

  // Compare year, month, and day
  return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
  );
}

export const formatDurationStr = (duration: string) => {
  const parts = duration.split(':');

  if (parts[0] === '00') {
      return parts.slice(1).join(':'); // Return only minutes and seconds
  } else {
      return [String(Number(parts[0])), parts[1], parts[2]].join(':'); // Convert hours to remove leading zeros
  }
}

export const formatISODuration = (duration: string) => {
  const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/;
  const matches = duration.match(regex);

  if (!matches) {
    throw new Error('Invalid ISO8601');
  }

  const [
    , // Ignore full match
    years,
    months,
    days,
    hours = 0,
    minutes = 0,
    seconds = 0
  ] = matches.map(match => (match === undefined ? 0 : parseInt(match, 10)));

  const formattedDuration = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedDuration;
}

export const formatISODate = (isoString: string) => {
  const date = new Date(isoString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO8601');
  }

  const months = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
  const days = String(date.getUTCDate()).padStart(2, '0');
  const years = String(date.getUTCFullYear()).slice(-2); // get last two digits of the year

  const formattedDate = `${months}/${days}/${years}`;
  return formattedDate;
}

export const formatMatchDuration = (timeString: string) => {
  // Check if the string starts with "00"
  if (timeString.startsWith("00:")) {
    // Return the string without the first three characters ("00:")
    return timeString.slice(3);
  }
  
  // If the string does not start with "00:", return it unchanged
  return timeString;
}

export const timeStringToISO8601 = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
  let isoDuration = 'P';

  if (hours > 0 || minutes > 0 || seconds > 0) {
    isoDuration += 'T';
    if (hours > 0) {
      isoDuration += `${hours}H`;
    }
    if (minutes > 0) {
      isoDuration += `${minutes}M`;
    }
    if (seconds > 0) {
      isoDuration += `${seconds}S`;
    }
  }

  return isoDuration;
}

export const getDayOfWeek = (isoDateString: string) => {
  const date = new Date(isoDateString);
  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  return date.getDay();
}

export const getMonthAndDay = (isoDateString: string) => {
  const date = new Date(isoDateString);  
  const day = date.getDate();  
  const monthIndex = date.getMonth();
  
  const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const monthName = months[monthIndex];  
  return `${monthName} ${day}`;
}

export const timeAgo = (date: string | number | Date) => {
  const oldDate: Date = new Date(date)
  const now: Date = new Date();
  const seconds = Math.floor((now.getTime() - oldDate.getTime()) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export const spliceSubstring = (str: string, substring: string) => {
  const index = str.indexOf(substring);
  
  if (index === -1) {
    // Substring not found, return the original string
    return {
      left: str,
      right: ''
    };
  }

  const left = str.slice(0, index);
  const right = str.slice(index + substring.length);

  return {
    left: left,
    right: right
  };
}

export const splitStr = (input: string, searchString: string) => {
  const index = input.indexOf(searchString);

  if (index !== -1) {
    const leftPart = input.slice(0, index).trim();
    const rightPart = searchString;

    return [leftPart, rightPart];
  } else {
    return [input, ""];
  }
}

export const renderShortTimeAgo = (dateTime: string | number | Date) => {
  const now = new Date();
  const past = new Date(dateTime);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return diffInSeconds === 0 ? "now" : `${diffInSeconds}s`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) {
    return `${diffInDays}d`;
  }

  const diffInWeeks = Math.floor(diffInDays / 7);
  return `${diffInWeeks}w`;
};

export const isEmptyString = (str: string) => {
  const regex = /^\s*$/;
  return regex.test(str);
}

export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];