const rawTimezones = [
  {
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT"
    ]
  },
  {
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "Eastern Standard Time",
    "abbr": "EST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ]
  },
]

export const getReadableTimezones = () => {
  return rawTimezones.map((timezoneInfo) => (timezoneInfo["value"]));
}

export const getMainTimeZone = (timezone: Date | string) => {
  for (let i = 0; i < rawTimezones.length; i++) {
    if (rawTimezones[i]["utc"][0] === timezone) {
      return rawTimezones[i]["value"]; 
    }
  }
  return "";
}

export const getTimezoneChoice = (timezone: Date | string) => {
  for (let i = 0; i < rawTimezones.length; i++) {
    if (rawTimezones[i]["value"] === timezone) {
      return rawTimezones[i]["utc"][0]; // arbitrarily choose first index
    }
  }
  return "";
}

export const getTimezoneDisplay = (timezone: Date | string) => {
  const now = new Date();

  const tzString = typeof timezone === 'string' ? timezone : timezone.toISOString();
  if (tzString.length === 0)
    return "";

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: tzString,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const formattedTime = formatter.format(now);
  return formattedTime;
}

export const getTimeUntil = (date: Date | string) => {
  const now = new Date();
  const deadline = new Date(date);

  const diffMs = deadline.getTime() - now.getTime();

  if (diffMs <= 0)
    return "00d 00h 00m 00s";

  const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  return `${days.toString().padStart(2, '0')}d ${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
}