type Emoji = {
  id: string;
  display: string;
}

export const emojis: Emoji[] = [
  { "id": "fire", "display": "fire 🔥" },
  { "id": "thumbs_up", "display": "thumbs_up 👍" },
  { "id": "+1", "display": "+1 👍" },
  { "id": "thumbup", "display": "thumbup 👍" },
  { "id": "heart", "display": "heart ❤️" },
  { "id": "red_heart", "display": "red_heart ❤️" },
  { "id": "clap", "display": "clap 👏" },
  { "id": "smile", "display": "smile 😊" },
  { "id": "party", "display": "party 🎉" },
  { "id": "grinning", "display": "grinning 😀" },
  { "id": "grinning_eyes", "display": "grinning_eyes 😄" },
  { "id": "tears_of_joy", "display": "tears_of_joy 😂" },
  { "id": "rofl", "display": "rofl 🤣" },
  { "id": "smile_eyes", "display": "smile_eyes 😊" },
  { "id": "heart_eyes", "display": "heart_eyes 😍" },
  { "id": "kiss", "display": "kiss 😘" },
  { "id": "smirk", "display": "smirk 😏" },
  { "id": "thinking", "display": "thinking 🤔" },
  { "id": "raised_eyebrow", "display": "raised_eyebrow 🤨" },
  { "id": "neutral", "display": "neutral 😐" },
  { "id": "expressionless", "display": "expressionless 😑" },
  { "id": "eye_roll", "display": "eye_roll 🙄" },
  { "id": "sweat_smile", "display": "sweat_smile 😅" },
  { "id": "scream", "display": "scream 😱" },
  { "id": "shock", "display": "shock 😲" },
  { "id": "blush", "display": "blush 😳" },
  { "id": "sleeping", "display": "sleeping 😴" },
  { "id": "dizzy", "display": "dizzy 😵" },
  { "id": "zipper_mouth", "display": "zipper_mouth 🤐" },
  { "id": "mask", "display": "mask 😷" },
  { "id": "thermometer", "display": "thermometer 🤒" },
  { "id": "injured", "display": "injured 🤕" },
  { "id": "nausea", "display": "nausea 🤢" },
  { "id": "vomit", "display": "vomit 🤮" },
  { "id": "sneeze", "display": "sneeze 🤧" },
  { "id": "hot", "display": "hot 🥵" },
  { "id": "cold", "display": "cold 🥶" },
  { "id": "woozy", "display": "woozy 🥴" },
  { "id": "party_face", "display": "party_face 🥳" },
  { "id": "skull", "display": "skull 💀" },
  { "id": "poo", "display": "poo 💩" },
  { "id": "clown", "display": "clown 🤡" },
  { "id": "ogre", "display": "ogre 👹" },
  { "id": "ghost", "display": "ghost 👻" },
  { "id": "alien", "display": "alien 👽" },
  { "id": "robot", "display": "robot 🤖" },
  { "id": "thumbs_down", "display": "thumbs_down 👎" },
  { "id": "thumbdown", "display": "thumbdown 👎"},
  { "id": "fist", "display": "fist ✊" },
  { "id": "victory", "display": "victory ✌" },
  { "id": "ok_hand", "display": "ok_hand 👌" },
  { "id": "ok", "display": "ok 👌"},
  { "id": "fingers_crossed", "display": "fingers_crossed 🤞" },
  { "id": "horns", "display": "horns 🤘" },
  { "id": "clapping", "display": "clapping 👏" },
  { "id": "raised_hands", "display": "raised_hands 🙌" },
  { "id": "folded_hands", "display": "folded_hands 🙏" },
  { "id": "pray", "display": "pray 🙏" },
  { "id": "wink", "display": "wink 😉" },
  { "id": "halo", "display": "halo 😇" },
  { "id": "confused", "display": "confused 😕" },
  { "id": "kissing", "display": "kissing 😗" },
  { "id": "shush", "display": "shush 🤫" },
  { "id": "monocle", "display": "monocle 🧐" },
  { "id": "nerd", "display": "nerd 🤓" },
  { "id": "sunglasses", "display": "sunglasses 😎" },
  { "id": "cool", "display": "cool 😎" },
  { "id": "star_struck", "display": "star_struck 🤩" },
  { "id": "cowboy", "display": "cowboy 🤠" },
  { "id": "tear_smile", "display": "tear_smile 😢" },
  { "id": "crying_face", "display": "crying_face 😭" },
  { "id": "symbols_mouth", "display": "symbols_mouth 🤬" },
  { "id": "cuss", "display": "cuss 🤬" },
  { "id": "exploding_head", "display": "exploding_head 🤯" },
  { "id": "pleading_face", "display": "pleading_face 🥺" },
  { "id": "plead", "display": "plead 🥺" },
  { "id": "crying_loudly", "display": "crying_loudly 😭" },
  { "id": "slightly_smiling", "display": "slightly_smiling 🙂" },
  { "id": "upside_down", "display": "upside_down 🙃" },
  { "id": "tongue", "display": "tongue 😛" },
  { "id": "zany", "display": "zany 🤪" },
  { "id": "freaky", "display": "freaky 🤪" },
  { "id": "squinting_tongue", "display": "squinting_tongue 😝" },
  { "id": "money_mouth", "display": "money_mouth 🤑" },
  { "id": "hushed", "display": "hushed 😯" },
  { "id": "astonished", "display": "astonished 😲" }
];

export const emojiIDs: string[] = [
  "fire 🔥",
  "thumbs_up 👍",
  "+1 👍",
  "thumbup 👍",
  "heart ❤️",
  "red_heart ❤️",
  "clap 👏",
  "smile 😊",
  "party 🎉",
  "grinning 😀",
  "grinning_eyes 😄",
  "tears_of_joy 😂",
  "rofl 🤣",
  "smile_eyes 😊",
  "heart_eyes 😍",
  "kiss 😘",
  "smirk 😏",
  "thinking 🤔",
  "raised_eyebrow 🤨",
  "neutral 😐",
  "expressionless 😑",
  "eye_roll 🙄",
  "sweat_smile 😅",
  "scream 😱",
  "shock 😲",
  "blush 😳",
  "sleeping 😴",
  "dizzy 😵",
  "zipper_mouth 🤐",
  "mask 😷",
  "thermometer 🤒",
  "injured 🤕",
  "nausea 🤢",
  "vomit 🤮",
  "sneeze 🤧",
  "hot 🥵",
  "cold 🥶",
  "woozy 🥴",
  "party_face 🥳",
  "skull 💀",
  "poo 💩",
  "clown 🤡",
  "ogre 👹",
  "ghost 👻",
  "alien 👽",
  "robot 🤖",
  "thumbs_down 👎",
  "thumbdown 👎",
  "fist ✊",
  "victory ✌",
  "ok_hand 👌",
  "ok 👌",
  "fingers_crossed 🤞",
  "horns 🤘",
  "clapping 👏",
  "raised_hands 🙌",
  "folded_hands 🙏",
  "pray 🙏",
  "wink 😉",
  "halo 😇",
  "confused 😕",
  "kissing 😗",
  "shush 🤫",
  "monocle 🧐",
  "nerd 🤓",
  "sunglasses 😎",
  "cool 😎",
  "star_struck 🤩",
  "cowboy 🤠",
  "tear_smile 😢",
  "crying_face 😭",
  "symbols_mouth 🤬",
  "cuss 🤬",
  "exploding_head 🤯",
  "pleading_face 🥺",
  "plead 🥺",
  "crying_loudly 😭",
  "slightly_smiling 🙂",
  "upside_down 🙃",
  "tongue 😛",
  "zany 🤪",
  "freaky 🤪",
  "squinting_tongue 😝",
  "money_mouth 🤑",
  "hushed 😯",
  "astonished 😲"
];


export const emojiMapper: Record<string, string> = {
  "fire 🔥": "🔥",
  "thumbs_up 👍": "👍",
  "+1 👍": "👍",
  "thumbup 👍": "👍",
  "heart ❤️": "❤️",
  "red_heart ❤️": "❤️",
  "clap 👏": "👏",
  "smile 😊": "😊",
  "party 🎉": "🎉",
  "grinning 😀": "😀",
  "grinning_eyes 😄": "😄",
  "tears_of_joy 😂": "😂",
  "rofl 🤣": "🤣",
  "smile_eyes 😊": "😊",
  "heart_eyes 😍": "😍",
  "kiss 😘": "😘",
  "smirk 😏": "😏",
  "thinking 🤔": "🤔",
  "raised_eyebrow 🤨": "🤨",
  "neutral 😐": "😐",
  "expressionless 😑": "😑",
  "eye_roll 🙄": "🙄",
  "sweat_smile 😅": "😅",
  "scream 😱": "😱",
  "shock 😲": "😲",
  "blush 😳": "😳",
  "sleeping 😴": "😴",
  "dizzy 😵": "😵",
  "zipper_mouth 🤐": "🤐",
  "mask 😷": "😷",
  "thermometer 🤒": "🤒",
  "injured 🤕": "🤕",
  "nausea 🤢": "🤢",
  "vomit 🤮": "🤮",
  "sneeze 🤧": "🤧",
  "hot 🥵": "🥵",
  "cold 🥶": "🥶",
  "woozy 🥴": "🥴",
  "party_face 🥳": "🥳",
  "skull 💀": "💀",
  "poo 💩": "💩",
  "clown 🤡": "🤡",
  "ogre 👹": "👹",
  "ghost 👻": "👻",
  "alien 👽": "👽",
  "robot 🤖": "🤖",
  "thumbs_down 👎": "👎",
  "thumbdown 👎": "👎",
  "fist ✊": "✊",
  "victory ✌": "✌",
  "ok_hand 👌": "👌",
  "ok 👌": "👌",
  "fingers_crossed 🤞": "🤞",
  "horns 🤘": "🤘",
  "clapping 👏": "👏",
  "raised_hands 🙌": "🙌",
  "folded_hands 🙏": "🙏",
  "pray 🙏": "🙏",
  "wink 😉": "😉",
  "halo 😇": "😇",
  "confused 😕": "😕",
  "kissing 😗": "😗",
  "shush 🤫": "🤫",
  "monocle 🧐": "🧐",
  "nerd 🤓": "🤓",
  "sunglasses 😎": "😎",
  "cool 😎": "😎",
  "star_struck 🤩": "🤩",
  "cowboy 🤠": "🤠",
  "tear_smile 😢": "😢",
  "crying_face 😭": "😭",
  "symbols_mouth 🤬": "🤬",
  "cuss 🤬": "🤬",
  "exploding_head 🤯": "🤯",
  "pleading_face 🥺": "🥺",
  "plead 🥺": "🥺",
  "crying_loudly 😭": "😭",
  "slightly_smiling 🙂": "🙂",
  "upside_down 🙃": "🙃",
  "tongue 😛": "😛",
  "zany 🤪": "🤪",
  "freaky 🤪": "🤪",
  "squinting_tongue 😝": "😝",
  "money_mouth 🤑": "🤑",
  "hushed 😯": "😯",
  "astonished 😲": "😲"
};
