import styled, { css } from 'styled-components';

// Define types for color and size options
type ColorType = 'gray' | 'gradient' | 'clear';
type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// Define size and color styles with strict typing
const sizes: Record<SizeType, ReturnType<typeof css>> = {
  xs: css`
    padding: 6px 14px;
    font-size: 12px;
  `,
  sm: css`
    padding: 8px 16px;
    font-size: 14px;
  `,
  md: css`
    padding: 12px 24px;
    font-size: 18px;
  `,
  lg: css`
    padding: 16px 32px;
    font-size: 24px;
  `,
  xl: css`
    padding: 32px 64px;
    font-size: 32px;
  `,
};

const colors: Record<ColorType, ReturnType<typeof css>> = {
  gray: css`
    background: #484B51;
    color: white;
    border: 1px solid #FFFFFF;
  `,
  gradient: css`
    background: linear-gradient(96deg, rgb(85, 226, 155) 0%, rgb(110, 125, 254) 52%, rgb(205, 108, 255) 100%);
    color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  `,
  clear: css`
    background: rgba(255, 255, 255, 0);
    color: white;
    border: 1px solid #D7FBFF;
  `,
};

// Define props for the Button component
interface ButtonProps {
  fontWeight?: string;
  letterSpacing?: string;
  color?: ColorType;
  size?: SizeType;
}

const Button = styled.button<ButtonProps>`
  color: white;
  font-weight: ${(props) => props.fontWeight || '800'};
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Poppins';
  letter-spacing: ${(props) => props.letterSpacing || '0'};

  ${(props) => colors[props.color || 'clear']};
  ${(props) => sizes[props.size || 'md']};
`;

export default Button;
