import './App.css'

import { AuthProvider } from "./components/AuthProvider";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import axios from 'axios';
import Feed from './pages/Feed';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Post from './pages/Post';
import Friends from './pages/Friends';
import Settings from './pages/Settings';
import Community from './pages/Community';
import SteamCallback from './components/Steamcallback';

let isRefreshing = false;
let failedQueue = [];

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.defaults.headers.post['Content-Type'] = "application/json";
axios.defaults.headers.patch['Content-Type'] = "application/json";

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
}

axios.interceptors.request.use(
  config => {
    const tokens = JSON.parse(localStorage.getItem("authTokens"));
    if (tokens) {
      config.headers["Authorization"] = `Bearer ${tokens["access"]}`
    }
    return config;
  },
  error => Promise.reject(error)
)

// Intercept 401 responses, force login
axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const refreshToken = authTokens ? authTokens["refresh"] : null;

        axios.post("/token/refresh", {
          "refresh": refreshToken
        })
        .then((response) => {
          localStorage.setItem("authTokens", JSON.stringify(response.data));
          axios.defaults.headers.common["Authorization"] = `Bearer ${response.data["access"]}`;
          originalRequest.headers["Authorization"] = `Bearer ${response.data["access"]}`;
          processQueue(null, response.data["access"]);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          window.location.href = "/login";
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
      });
    }

    if (error.response && error.response.status === 401 && originalRequest._retry) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/settings"
            element={<Settings />}
          />
          <Route path="/community" element={<Community />} />
          <Route path="/profile/:username"
            element={<Profile />}
          />
          <Route path="/friends" element={<Friends />} />
          <Route path="/steam-callback" element={<SteamCallback />} />
          <Route path="/posts/:postID"
            element={<Post />}
          />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App;