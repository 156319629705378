import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import AuthContext from './AuthProvider';
import { useContext } from 'react';
import { getAuthHeader } from '../utils';
import { BaseHeaderType } from '../types/Auth';

const SteamCallback = () => {
    const [requireUsername, setRequireUsername] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newUsername, setNewUsername] = useState("");
    const [steamId, setSteamId] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const hasExecuted = useRef(false);
    const { handleSteamAuth } = useContext(AuthContext);

    useEffect(() => {
        const verifySteamLogin = async () => {
            if (hasExecuted.current) return;
            hasExecuted.current = true;
            const queryParams = Object.fromEntries([...searchParams]);
            
            try {
                const useAuth = (searchParams.get('auth') === 'true');
                let response;
                if (useAuth) {
                  // just for setting steam ID on already existing account
                  let baseHeaders: BaseHeaderType = getAuthHeader();
                  baseHeaders["headers"]["Content-Type"] = "application/json";
                  response = await fetch(`${process.env.REACT_APP_API_URL}/users/steam_verify/`, {
                      method: "POST",
                      headers: baseHeaders["headers"],
                      body: JSON.stringify(queryParams),
                  });
                  navigate('/settings');
                  return;
                } else {
                  response = await fetch(`${process.env.REACT_APP_API_URL}/users/steam_verify/`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      body: JSON.stringify(queryParams),
                  });
                }

                const data = await response.json();
                setSteamId(data?.user?.steam_id);
                if (response.ok) {
                    if (data.status === 'duplicate_username') {
                        setRequireUsername(true);
                        return;
                    }

                    const success = await handleSteamAuth(
                        data.tokens,
                        data.status === 'new_user' ? data.user : null
                    );

                    if (!success && data.status !== 'duplicate_username') {
                        console.error('Failed to set Steam auth');
                        navigate('/login?error=auth_failed');
                    }
                } else {
                    console.error('Steam verification failed:', data.detail);
                    navigate('/login?error=verification_failed');
                }
            } catch (error) {
                console.error('Error during Steam verification:', error);
                navigate('/login?error=verification_failed');
            }
        };
        
        verifySteamLogin();
        
    }, [searchParams, navigate, handleSteamAuth]);

    const handleSubmitNewUsername = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/users/steam_finish_profile/?steam_id=${steamId}&new_username=${newUsername}`, {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
              },
          });

          const data = await response.json();
          if (response.ok) {
              const success = await handleSteamAuth(
                  data.tokens,
                  data.status === 'new_user' ? data.user : null
              );

              if (!success) {
                  console.error('Failed to set Steam auth');
                  navigate('/login?error=auth_failed');
              }
          } else {
              alert("An error occurred while setting your new username. Please try a different username")
          }
      } catch (error) {
          console.error('Error during Steam verification:', error);
          navigate('/login?error=verification_failed');
      }
      setLoading(false);
    }

    return (
        <div className="h-screen w-full flex items-center justify-center">
            {(!requireUsername) && <div className="text-white text-xl">
              Processing Steam login...
            </div>}
            {(requireUsername) &&
            <div className="text-white text-xl flex flex-col gap-6 w-2/3">
              <span className="text-center">
                We've detected that your public Steam username is identical to someone already on HiLo! Please enter a new username below:
              </span>
              <input type="text" className="text-black w-[35%] mx-auto"
                onChange={(e) => {
                  setNewUsername(e.target.value);
                }}
                value={newUsername}
              />
              <button
                className="text-white mx-auto font-bold hover:cursor-pointer hover:bg-slate-400 font-poppins rounded-3xl px-[24px] py-[8px] border-[1px] border-white w-32"
                onClick={handleSubmitNewUsername}
              >
                {(!loading) ? "Submit" : "Loading..."}
              </button>
            </div>}
        </div>
    );
};

export default SteamCallback;