import React, { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getAuthHeader } from "../utils";

import { Comment } from "../types/Comment";

interface CommentToolTipProps {
  postID: number | string;
  comment: Comment;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  trigger: boolean;
  setSeeMore: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommentToolTip = ({postID, comment, setTrigger, trigger, setSeeMore}: CommentToolTipProps) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleDeleteComment = (comment: Comment) => {
      axios.delete(`/posts/${postID}/comments/?id=${comment.id}`, getAuthHeader())
      .then((response) => {
      setSeeMore(false);
      setTrigger(!trigger);
    })
  }
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowToolTip(false);
      }
    };

    if (showToolTip) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showToolTip]);

  return (
    <>
      <div className="flex flex-row gap-2 items-start relative">
        {(showToolTip) && 
        <div ref={tooltipRef} className="text-white text-sm absolute left-full min-w-6 z-50">
          <ul className="border-[1px] border-[#D7FBFF] rounded-xl bg-gray-800 py-1 px-1">
            <div className="text-[#D9D9D9] text-[10px] text-poppins hover:underline hover:cursor-pointer"
              onClick={(e) => {
                setShowToolTip(false);
                handleDeleteComment(comment); 
              }}
            >
              Delete
            </div>
          </ul>
        </div>}
        <button
          className="relative sm:p-1.5 sm:rounded-full focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            setShowToolTip(!showToolTip);
          }}
        >
          <BsThreeDotsVertical size={14} />
        </button>
      </div>
    </>
  );
}

export default CommentToolTip;