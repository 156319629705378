import React, { useState, useEffect, useRef, forwardRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineLoading } from "react-icons/ai";
import { DropdownContainer, DropdownButton, DropdownMenu, DropdownItem } from "../components/Dropdown";
import Leaderboard from "../components/Leaderboard";
import { Link } from "react-router-dom";
import { IconContext } from 'react-icons/lib';
import MainLayout from "../layout/MainLayout";
import CommunityCard from "../components/CommunityCard";
import axios from "axios";
import useWindowSize from "../hooks/useWindowSize";

import { getAuthHeader } from "../utils";
import useWindowDimensions from "../hooks/useWindowSize";
import { CommunityUser } from "../types/User";

const nameOptions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "Other"];
const rankOptions = ["Immortal", "Divine", "Ancient", "Legend", "Archon", "Crusader", "Guardian", "Herald"];
const positionOptions = ["Hard Support", "Soft Support", "Off Lane", "Mid Lane", "Safe Lane"];

interface DropdownProps {
  toggleDropdown: () => void;
  handleSelectDropdown: (v: string) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
  showDrop: boolean;
  options: string[];
  value: string;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ toggleDropdown, handleSelectDropdown, showDrop, options, value }, ref) => {
  return (
    <DropdownContainer style={{marginBottom: "5px"}} ref={ref}>
      <DropdownButton onClick={toggleDropdown}>
        <span className="flex flex-row items-center gap-2">
          <div className="pr-1 text-[10px] sm:text-[15px] font-bold text-white">{value}</div>
          <IoIosArrowDown />
        </span>
      </DropdownButton>
      <DropdownMenu show={showDrop}>
        {(options).map((option) => {
          return (
            <DropdownItem onClick={handleSelectDropdown(option)}
              hoverColor={"#808080"}
              className="hover:cursor-pointer"
            >
              {option}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </DropdownContainer>
  )
});

interface SortButtonProps {
  toggleButton: () => void;
  isUp: boolean;
  value: string;
}

const SortButton = ({ toggleButton, isUp, value }: SortButtonProps) => {
  return (
    <DropdownContainer style={{marginBottom: "5px"}}>
      <DropdownButton onClick={toggleButton}>
        <span className="flex flex-row items-center gap-2">
          <div className="pr-1 text-[8px] sm:text-[15px] font-bold text-white">{value}</div>
          {(isUp) ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </span>
      </DropdownButton>
    </DropdownContainer>
  )
}


const Community = () => {
  const [name, setName] = useState("Name");
  const [showNameDrop, setShowNameDrop] = useState(false);
  const nameRef = useRef<HTMLDivElement>(null);

  const [mostRecent, setMostRecent] = useState(true);

  const [rank, setRank] = useState("Rank");
  const [showRankDrop, setShowRankDrop] = useState(false);
  const rankRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState("Position");
  const [showPosDrop, setShowPosDrop] = useState(false);
  const posRef = useRef<HTMLDivElement>(null);

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [activeTab, setActiveTab] = useState("Desktop");

  const { height, width } = useWindowDimensions();


  const handleSelectName = (newName: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setName(newName);
    setShowNameDrop(false);
  }

  const toggleNameDrop = () => {
    setShowNameDrop(!showNameDrop);
  }

  const toggleMostRecent = () => {
    setMostRecent(!mostRecent);
  }

  const handleSelectRank = (newRank: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setRank(newRank);
    setShowRankDrop(false);
  }
  const toggleRankDrop = () => {
    setShowRankDrop(!showRankDrop);
  }

  const handleSelectPos = (newPos: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setPosition(newPos);
    setShowPosDrop(false);
  }
  const togglePosDrop = () => {
    setShowPosDrop(!showPosDrop);
  }
  const handleResetFilters = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setName("Name");
    setRank("Rank");
    setPosition("Position");
  }

  useEffect(() => {
    if (width >= 640) { 
      setActiveTab('Desktop');
    } else {
      if (activeTab == "Desktop"){
          setActiveTab("Leaderboard")
      }
    }
  }, [width]);

  useEffect(() => {
    setLoadingUsers(true);
    axios.post("/users/community_search/", {
      mostRecent: mostRecent,
      name: name,
      rank: rank,
      position: position,
    }, getAuthHeader())
    .then((response) => {
      setUsers(response.data);
      setLoadingUsers(false);
    });
  }, [name, rank, mostRecent, position]);

  const handleCloseNameDrop = (e: MouseEvent) => {
    if (showNameDrop && !nameRef.current?.contains(e.target as Node)) {
      setShowNameDrop(false);
    }
  }
  document.addEventListener('mousedown', handleCloseNameDrop)

  const handleCloseRankDrop = (e: MouseEvent) => {
    if (showRankDrop && !rankRef.current?.contains(e.target as Node)) {
      setShowRankDrop(false);
    }
  }
  document.addEventListener('mousedown', handleCloseRankDrop)

  const handleClosePosRef = (e: MouseEvent) => {
    if (showPosDrop && !posRef.current?.contains(e.target as Node)) {
      setShowPosDrop(false);
    }
  }
  document.addEventListener('mousedown', handleClosePosRef)

  return (
    <MainLayout>
      <div className="flex w-full text-white justify-center item-center">
        <div className="flex space-x-4 mb-4 sm:hidden mt-4">
          <button
            className={`${activeTab === 'Leaderboard' ? 'text-cyan-500 underline underline-offset-8' : 'text-white-500'}`}
            onClick={() => setActiveTab('Leaderboard')}
          >
            Leaderboard
          </button>
          <button
            className={`${activeTab === 'Users' ? 'text-cyan-500 underline underline-offset-8' : 'text-white-500'}`}
            onClick={() => setActiveTab('Users')}
          >
            Team Alpha
          </button>
        </div>
      </div>
      <div className="flex flex-col px-4 py-4 sm:px-20 sm:py-20 gap-8 overflow-auto">   
        {
          (activeTab === "Leaderboard" || activeTab === "Desktop") && 
          <div>
            <h1 className="font-poppins text-[24px] text-white max-sm:hidden">
              Leaderboards
            </h1>
            <div className="flex flex-row flex-wrap relative gap-3 my-3">
              <div className={"flex-1 flex w-full max-sm:mx-3"}>
                <Leaderboard 
                location={"community_1"}
                >
                </Leaderboard>
              </div>
              <div className="flex-1 flex w-full max-sm:mx-3">
                <Leaderboard 
                location={"community_2"}
                >
                </Leaderboard>
              </div>
              <div className="flex-1 flex w-full max-sm:mx-3">
                <Leaderboard 
                location={"community_3"}
                >
                </Leaderboard>
              </div>          
            </div>
          </div>  
        }            
        {(activeTab == "Users" || activeTab == "Desktop") && 
          <div>
          
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 sm:my-4">
          

          <div className="flex flex-row gap-2 relative">
            <h1 className="font-poppins text-[24px] max-sm:hidden text-white">Team Alpha</h1>
            {(loadingUsers) && <span className="absolute animate-spin h-10 top-0 left-40">
              <IconContext.Provider
                value={{ color: '#FFF'}}
              >
                <AiOutlineLoading size={40} />
              </IconContext.Provider>
            </span>}
          </div>
          <div className="flex flex-row sm:ml-auto gap-4 items-center flex-wrap max-sm:my-4">
            <a className="text-blue-400 hover:underline hover:cursor-pointer text-[14px] sm:text-[18px] max-sm:hidden"
              onClick={handleResetFilters}
            >
              Reset
            </a>
            <Dropdown
              toggleDropdown={toggleNameDrop}
              handleSelectDropdown={handleSelectName}
              showDrop={showNameDrop}
              options={nameOptions}
              value={name}
              ref={nameRef}
            />
            <SortButton 
              toggleButton={toggleMostRecent}
              isUp={mostRecent}
              value="Last Played"
            />
            <Dropdown
              toggleDropdown={toggleRankDrop}
              handleSelectDropdown={handleSelectRank}
              showDrop={showRankDrop}
              options={rankOptions}
              value={rank}
              ref={rankRef}
            />
            <Dropdown
              toggleDropdown={togglePosDrop}
              handleSelectDropdown={handleSelectPos}
              showDrop={showPosDrop}
              options={positionOptions}
              value={position}
              ref={posRef}
            />
          </div>
        </div>
        {(!loadingUsers) && <div className="grid grid-cols-2 sm:grid-cols-4 gap-10">
          {(users).map((user: CommunityUser) => {
            const profileLink = `/profile/${user?.username}`;
            return (
              <Link to={profileLink}>
                <CommunityCard className="col-span-1" cardInfo={user} />
              </Link>
            )
          })}
        </div>}
        </div>
      }
      </div>
    </MainLayout>
  )
}

export default Community;