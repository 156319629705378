import React,{ useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import Confetti from 'react-confetti'
import { Link } from "react-router-dom";

import { Duel, DuelAttempt, TopDuelAttempt } from "../types/Duel";
import { getAuthHeader, DOTA2_LANE_ICON_MAP } from "../utils";
import { getTimeUntil } from "../timezones";
import AuthContext from "./AuthProvider";
import { UserJWT, User } from "../types/User";

import { RxCross1 } from "react-icons/rx";
import HiloCrown from "../assets/hilo-crown.png";
import ReactConfetti from "react-confetti";


const renderTopTableHead = (duel: Duel) => {
  if (duel?.game === "Dota 2") {
    const statsMap: { [key: string]: string } = {
      kda: "KDA",
      hero_damage: "Hero Damage",
      networth: "Gold",
      hero_healing: "HP Healed",
      last_hits: "Last Hits",
      deaths: "Deaths",
      kills: "Kills",
      assists: "Assists",
    };

    const statLabel = statsMap[duel.type];
    if (!statLabel) return null;

    return (
      <tr className="h-4 min-w-full relative font-poppins text-white">
        <th className="h-2 w-[12%] pt-1 sm:h-6 md:text-[11px] xl:text-sm font-light text-left">Rank</th>
        <th className="h-2 w-1/3 pt-1 sm:h-6 md:text-[11px] xl:text-sm font-light text-left">Player</th>
        <th className="h-2 w-1/6 pt-1 sm:h-6 md:text-[11px] xl:text-sm font-light text-left">Hero</th>
        <th className="h-2 w-1/5 pt-1 sm:h-6 md:text-[11px] xl:text-sm font-light text-left">{statLabel}</th>
        <th className="h-2 w-1/12 pt-1 sm:h-6 md:text-[11px] xl:text-sm font-light text-left">Tries</th>
      </tr>
    );
  }
  return null;
}

const renderTopAttempt = (game: string, attempt: TopDuelAttempt, idx: number, user: UserJWT | null) => {
  let rowBg;
  let leftRounded;
  let rightRounded;
  let isMe = attempt.user_id === user?.user_id;

  if (idx === 0) {
    rowBg = "h-4 w-6 sm:h-9 sm:w-8 align-middle bg-gradient-to-r from-[#A2ECE44D] via-[#BA99FB4D] to-[#CD6CFF4D]";
    leftRounded = "h-2 w-[12%] sm:h-6 text-left rounded-bl-md rounded-l-md";
    rightRounded = "h-2 w-1/12 sm:h-6 text-center rounded-br-md rounded-r-md xl:text-sm md:text-[11px]";
  } else {
    rowBg = "h-4 w-6 sm:h-9 sm:w-8 align-middle";
    leftRounded = "h-2 w-[12%] sm:h-6 text-left";
    rightRounded = "h-2 w-1/12 sm:h-6 text-center xl:text-sm md:text-[11px]";
  }

  switch (game) {
    case "Dota 2":
      return (
        <tr className={rowBg} key={idx}>
          {(idx+1 === 1) ? 
          <td className={leftRounded}>
            <div className="rounded-full h-8 w-8 md:h-6 md:w-6 xl:h-8 xl:w-8 overflow-hidden object-cover">
              <img src={HiloCrown} className="w-full h-full" />
            </div>
          </td> : <td className={"h-2 w-[12%] sm:h-6 pl-3 text-sm md:text-[11px] xl:text-sm" + (isMe ? " text-[#00E5FF]" : "")}>{idx + 1}</td>}
          <td className="h-2 w-1/3 sm:h-6 text-left">
            <Link className='flex items-center space-x-2 md:space-x-1 xl:space-x-2' to={`/profile/${attempt.username}`}>
              <div className="align-left h-8 w-8 md:h-6 md:w-6 xl:h-8 xl:w-8 relative items-end overflow-hidden rounded-full border-[1px] border-black">
                  <img src={attempt.profile_pic} alt="" className="absolute top-0 left-0 w-full h-full object-cover" /> 
              </div>
              <span className={"text-left text-[14px] md:text-[11px] xl:text-[14px] font-light drop-shadow-2xl" + (isMe ? " text-[#00E5FF]" : "")}>{attempt.username}</span>
            </Link>
          </td>
          <td className="h-2 w-1/6 sm:h-6 text-left">
            <div className="align-left h-8 w-8 md:h-6 md:w-6 xl:h-8 xl:w-8 relative items-end overflow-hidden rounded-full">
              <img src={attempt.hero_played} alt="" className="absolute w-full h-full object-cover" />
            </div>
          </td>
          <td className="h-2 w-1/5 sm:h-6 text-[14px] md:text-[11px] xl:text-[14px] text-left">
            {attempt.stat}
          </td>
          <td className={rightRounded}>
            {attempt.num_tries}
          </td>
        </tr>
      );
    default:
      return null;
  }
}

const renderRecentAttempt = (game: string, attempt: DuelAttempt, idx: number) => {
  switch (game) {
    case "Dota 2":
      const pos = DOTA2_LANE_ICON_MAP[attempt.position as keyof typeof DOTA2_LANE_ICON_MAP];
      return (
        <tr className="h-4 w-6 sm:h-8 sm:w-8 align-middle" key={idx}>
          <td className="h-2 w-1/3 sm:h-6 text-left">
            <Link className='flex items-center space-x-2 sm:space-x-1 xl:space-x-2' to={`/profile/${attempt.username}`}>
              <div className="align-left h-8 w-8 md:h-6 md:w-6 xl:h-8 xl:w-8 relative items-end overflow-hidden rounded-full border-[1px] border-black">
                  <img src={attempt.profile_pic} alt="" className="absolute top-0 left-0 w-full h-full object-cover" /> 
              </div>
              <span className="text-left text-[14px] md:text-[11px] xl:text-[14px] font-light drop-shadow-2xl">{attempt.username}</span>
            </Link>
          </td>
          <td className="h-2 w-1/6 sm:h-6 text-left">
            <div className="align-left h-8 w-8 md:h-6 md:w-6 xl:h-8 xl:w-8 relative items-end overflow-hidden rounded-full">
              <img src={attempt.hero_played} alt="" className="absolute w-full h-full object-cover" />
            </div>
          </td>
          <td className="h-2 w-1/4 sm:h-6 text-[14px] md:text-[11px] xl:text-[14px] text-left">
            {attempt.stat}
          </td>
          <td className="h-2 w-1/6 sm:h-6 text-center">
            {(attempt.position) && <div className="h-6 w-6 md:h-4 md:w-4 xl:h-6 xl:w-6 overflow-hidden object-contain">
              <img src={pos} />
            </div>}
          </td>
        </tr>
      );
    default:
      return null;
  }
}


const renderTopAttempts = (
  duel: Duel, user: UserJWT | null,
  confettiRefL: React.RefObject<HTMLCanvasElement>, confettiRefR: React.RefObject<HTMLCanvasElement>,
  timer: string
) => {
  const topAttempts = duel.top_attempts;
  const userTopAttempt = (duel?.user_top_attempt && Object.keys(duel?.user_top_attempt).length > 0) ? duel.user_top_attempt : null;
  return (
    <table className="text-white table-auto border-spacing-3 z-40">
      <thead>
        {renderTopTableHead(duel)}
      </thead>
      <tbody className="h-2 w-4 sm:h-10 sm:w-10 relative z-40">
        {(timer === "00d 00h 00m 00s") && <div className="absolute top-0 left-1">
          <ReactConfetti
            width={30}
            height={30}
            drawShape={(ctx) => {
              ctx.beginPath();
              ctx.arc(0, 0, 2.5, 0, 2 * Math.PI);
              ctx.fill();
            }}
            ref={confettiRefL}
          />
        </div>}
        {(timer === "00d 00h 00m 00s") && <div className="absolute top-0 right-10">
          <ReactConfetti
            width={40}
            height={30}
            drawShape={(ctx) => {
              ctx.beginPath();
              ctx.arc(0, 0, 2.5, 0, 2 * Math.PI);
              ctx.fill();
            }}
            ref={confettiRefR}
          />
        </div>}
        {
          (topAttempts).map((attempt, idx) => {
            return (
              renderTopAttempt(duel.game, attempt, idx, user)
            );
          })
        }
        {userTopAttempt && renderTopAttempt(duel.game, userTopAttempt, userTopAttempt.rank, user)}
      </tbody>
    </table>
  )
}

const renderRecentAttempts = (duel: Duel) => {
  const recentAttempts = duel.recent_attempts;

  // arrange by days
  let attemptGroups: Record<string, Array<any>> = {};
  for (let i = 0; i < recentAttempts.length; i++) {
    let date = new Date(recentAttempts[i].time);
    let hash = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    if (!(hash in attemptGroups))
      attemptGroups[hash] = [];
    attemptGroups[hash].push(recentAttempts[i]);
  }
  const dayAttempts = Object.values(attemptGroups);
  let dayLabels = Object.keys(attemptGroups);

  const daysAgo = (date: Date) => {
    const now = new Date();
    const diff = Math.floor((now.getTime() - date.getTime()) / 86400000);
    if (diff >= 2) {
      return `${diff} days ago`;
    } else if (diff === 1) {
      return `Yesterday`;
    } else {
      return `Today`;
    }
  }

  return (
    <>
      {(dayLabels).map((dateStr, i) => {
        let dateObj = new Date(dateStr);
        let currAttempts = dayAttempts[i];
        return (
          <table className="text-white table-auto border-spacing-3 z-40">
            <thead>
              <tr className="h-4 min-w-full relative font-poppins">
                <th className="h2 w-1/2 pt-1 sm:h-6 md:text-sm xl:text-base font-light text-left z-20">
                  {daysAgo(dateObj)}
                </th>
              </tr>
            </thead>
            <tbody className="h-2 w-4 sm:h-10 sm:w-10 relative z-40">
              {
                (currAttempts).map((attempt, i) => renderRecentAttempt(duel.game, attempt, i))
              }
            </tbody>
          </table>
        );
      })}
    </>
  )
}

const renderMobileIcons = (duel: Duel, setShowFullDuel: React.Dispatch<React.SetStateAction<boolean>>) => {
  switch (duel.game) {
    case "Dota 2":
      return (
        <span className="flex flex-row gap-1 justify-between"
          onClick={() => {
            setShowFullDuel(true);
          }} 
        >
          {duel.top_attempts.slice(0, 4).map((attempt) => {
            return (
              <div className="w-8 h-8 rounded-md overflow-hidden">
                <img src={attempt.hero_played} alt="" className="w-full h-full object-cover" />
              </div>
            )
          })}
        </span>
      )
  }
}

interface DuelDisplayProps {
  showFullDuel: boolean;
  setShowFullDuel: React.Dispatch<React.SetStateAction<boolean>>;
}

const DuelDisplay = ({ showFullDuel, setShowFullDuel }: DuelDisplayProps) => {
  const [duel, setDuel] = useState<Duel | null>(null);
  const [timer, setTimer] = useState("");
  const [joined, setJoined] = useState(false);
  const [numParticipants, setNumParticipants] = useState(0);
  const [deadlineDate, setDeadlineDate] = useState("");
  const confettiRefL = useRef<HTMLCanvasElement>(null);
  const confettiRefR = useRef<HTMLCanvasElement>(null);
  const [validDuel, setValidDuel] = useState(false);
  const {user} = useContext(AuthContext);
  const isMobile = (window.screen.width < 600);

  const [duelWinner, setDuelWinner] = useState<User | null>(null);

  useEffect(() => {
    axios.get("/duels/duel/", getAuthHeader())
    .then((response) => {
      if (Object.keys(response.data).length === 0)
        return;

      setDuel(response.data);
      setJoined(response.data.user_joined);
      setNumParticipants(response.data.num_participants);
      if (Object.keys(response.data.winner).length !== 0)
        setDuelWinner(response.data.winner);


      const deadline = new Date(response.data.deadline);
      const formattedDate = deadline.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
      });
      setDeadlineDate(formattedDate);
      setValidDuel(true);
  
      const updateTimer = () => {
        setTimer(getTimeUntil(response.data.deadline))
      }
      const intervalID = setInterval(updateTimer, 1000);
      updateTimer();
      return () => clearInterval(intervalID)
    })
  }, []);

  const handleJoinDuel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios.put("/duels/duel/", getAuthHeader())
    .then((response) => {
      setJoined(true);
      setNumParticipants(numParticipants+1);
    })
  }

  return (
    <>
      {(duelWinner && !isMobile) && <div className="border-[#A3ECE4] border-[1px] p-2 sm:p-4 rounded-md flex flex-col gap-1 bg-black bg-center relative sm:w-full mt-4 mb-8">
        <div className="flex flex-row gap-2 mx-auto">
          <h1 className="text-white text-[20px] font-sans">This Week's Duel Winner:</h1>
        </div>
        <div className="max-w-72 max-h-72 overflow-hidden rounded-md mx-auto">
          <img src={duel?.winner_media} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-row gap-2 mx-auto">
          <a 
            className="text-white text-[22px] font-sans hover:cursor-pointer hover:underline"
            href={`/profile/${duelWinner.username}`}
          >
            {duelWinner.username}
          </a>
        </div>
      </div>}
      {(duel && validDuel) && <div className={"border-[#A3ECE4] border-[1px] p-2 sm:p-4 rounded-md flex flex-col gap-3 bg-duel bg-cover bg-center relative sm:w-full" + (showFullDuel ? " mt-8" : " w-1/3")}>
        <div className="absolute h-full w-full bg-black opacity-70 top-0 left-0 rounded-md z-0" />
        {(showFullDuel) && <button className="absolute -top-8 right-0 text-white z-40"
          onClick={(e) => {
            e.preventDefault();
            setShowFullDuel(false);
          }}
        >
          <RxCross1 size={20} />
        </button>}
        {(isMobile && !showFullDuel) && <div className="flex flex-col z-10 gap-3">
          <div className="flex flex-col"
            onClick={() => {
              setShowFullDuel(true);
            }}   
          >
            <h1 className="font-poppins font-bold text-[14px] text-[#A2ECE4]">{duel.title}</h1>
          </div>
          {(!joined && duel) && <button
            className="bg-[#41B5BC] border-2 rounded-md font-poppins text-[12px] text-white font-light border-none py-1 hover:bg-opacity-45 z-40"
            onClick={handleJoinDuel}
          >
            Join
          </button>}
          {(joined && duel) && <p className="font-sans font-normal text-center text-[12px] text-white bg-[#565656] rounded-md border-none px-1 py-1"
            onClick={() => {
              setShowFullDuel(true);
            }}        
          >
            {timer}
          </p>}
          {renderMobileIcons(duel, setShowFullDuel)}
        </div>}

        {(!isMobile || showFullDuel) && <>
          <div className="flex flex-row z-40">
            <div className="flex flex-col gap-2 flex-1 justify-between">
              <h1 className="font-poppins font-bold md:text-lg xl:text-[22px] text-[#A2ECE4]">{duel.title}</h1>
              <div className="flex flex-col md:text-[11px] xl:text-[14px] text-white font-poppins">
                <p>Duelists: {numParticipants}</p>
                <p>Attempts: {duel.num_attempts}</p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              {(!joined && duel) && <>
                <div className="flex flex-col font-sans font-normal text-white text-center">
                  <p className="md:text-[11px] xl:text-[14px]">Time Remaining: {deadlineDate}</p>
                  <p className="md:text-sm xl:text-[18px]">{timer}</p>
                </div>
                <button
                  className="bg-[#41B5BC] border-2 rounded-md font-poppins text-[13px] text-white font-light border-none py-2 hover:bg-opacity-45"
                  onClick={handleJoinDuel}
                >
                  Join
                </button>
              </>}
              {(joined && duel) && <div className="text-center flex flex-col gap-2">
                <p className="font-sans font-normal text-[15px] my-auto text-white">
                  Time Remaining: {deadlineDate}
                </p>
                <p className="font-sans font-normal text-[20px] text-white bg-[#565656] rounded-md border-none py-2 px-2">
                  {timer}
                </p>
              </div>}
            </div>
          </div>
          {renderTopAttempts(duel, user, confettiRefL, confettiRefR, timer)}
          <h1 className="font-poppins font-bold md:text-base xl:text-[18px] text-[#DAFFFB] text-center z-10">Attempts</h1>
          {renderRecentAttempts(duel)}
        </>}
      </div>}
      {(duelWinner && isMobile && !showFullDuel) && <div className="border-[#A3ECE4] border-[1px] p-2 sm:p-4 rounded-md flex flex-col gap-1 bg-black bg-center relative sm:w-full">
        <div className="flex flex-row gap-2 mx-auto">
          <h1 className="text-white text-[14px] font-sans">
            Congrats{' '}
            <a className="hover:underline" href={`/profile/${duelWinner.username}`}>{duelWinner.username}</a>!
          </h1>
        </div>
        <div className="max-h-32 max-w-32 overflow-hidden rounded-md mx-auto">
          <img src={duel?.winner_media} className="w-full h-full object-cover" />
        </div>
      </div>}
    </>
  )
}

export default DuelDisplay;