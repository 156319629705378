import React, { useContext, useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios';
import AnonymousAvatar from "../assets/hilo-avatar.png";
import HiloIcon from "../assets/hilo-anon.png";
import { getAuthHeader, timeAgo, truncateStr, splitStr } from '../utils';

import '../styles/MainLayout.css';
import { User } from '../types/User';
import { Notification } from '../types/Notification';

interface MainLayoutProps {
  children: React.ReactNode;
  userInfo?: User | null;
}

const MainLayout = ({ children, userInfo = null }: MainLayoutProps) => {
  const [showNotifs, setShowNotifs] = useState(false);
  const [notifs, setNotifs] = useState<Notification[]>([]);
  const [hasNew, setHasNew] = useState(false);
  const isMobile = (window.screen.width < 600);

  useEffect(() => {
    const updateNotifs = () => {
      axios.get("/users/notifications/", getAuthHeader())
        .then((response) => {
          setNotifs(response.data["notifs"]);
          setHasNew(response.data["has_new_notifs"]);
        });
    }

    updateNotifs();

    const intervalId = setInterval(updateNotifs, 30000); // 30 secs
    return () => clearInterval(intervalId);
  }, []);

  const markAsRead = (notif: Notification) => {
    axios.patch("/users/notifications/", {
      notif_id: notif?.id,
    }, getAuthHeader())
    .then((response) => {
      if (!response.data["has_new_notifs"])
        setHasNew(false);
    });
  }

  const markAllAsRead = () => {
    const notifIDs = notifs.map((notif: Notification) => notif.id)
    axios.patch("/users/notifications/", JSON.stringify({
      notif_ids: notifIDs
    }), getAuthHeader())
    .then((response) => {
      setHasNew(false);
    })
  }

  const handleAcceptFriendRequest = (notif: Notification) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    axios.patch(`/users/${notif?.user}/friend_requests/?action=accept`, {
      "friend_request_id": notif?.friend_request?.id
    })
    .then((response) => {
      setNotifs((currNotifs) => currNotifs.filter((currNotif: Notification) => currNotif.id !== notif.id));
    });
  }

  const handleDeclineFriendRequest = (notif: Notification) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    axios.patch(`/users/${notif?.user}/friend_requests/?action=decline`, {
      "friend_request_id": notif?.friend_request?.id 
    })
    .then((response) => {
      setNotifs((currNotifs) => currNotifs.filter((currNotif: Notification) => currNotif.id !== notif.id));
    });
  }

  const renderNotifBody = (notif: Notification) => {
    switch (notif?.notif_type) {
      case "friend_request":
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] flex flex-col gap-1">
            <a
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `/profile/${notif?.tagged_user?.username}`;
              }}
              className="hover:cursor-pointer"
            >
              <span className="font-semibold text-white">{notif?.tagged_user?.username}</span>{' '}
              sent you a friend request!
            </a>
            <div className="flex flex-row gap-2">
              <button
                className="text-[black] px-2 py-0.5 text-[13px] hover:cursor-pointer bg-gradient-to-r from-[#A2ECE4] via-[#BA99FB] to-[#CD6CFF] rounded-lg"
                onClick={handleAcceptFriendRequest(notif)}
              >
                Accept
              </button>
              <button
                className="text-[black] bg-[#E1E1E1] px-2 py-0.5 text-[13px] hover:cursor-pointer rounded-lg"
                onClick={handleDeclineFriendRequest(notif)}
              >
                Decline
              </button>
            </div>
          </div>
        )
      case "comment_mention":
        const mentionPattern = /@\[(\w+)\]/g;
        const commentBody = notif?.comment?.body.replace(mentionPattern, (match, p1) => `@${p1}`) || "";
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.comment?.post}`;
            }}
          >
            <span className="font-semibold text-white">{notif?.tagged_user?.username}{' '}</span>
            mentioned you in a comment: "{truncateStr(commentBody, 25)}..."
          </div>
        );
      case "post_mention":
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.post}`
            }}
          >
            <span className="font-semibold text-white">{notif?.tagged_user?.username}{' '}</span>
            mentioned you in a post: "{truncateStr(notif?.body, 25)}"
          </div>
        )
      case "widget_change":
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] hover:cursor-pointer">
            {(notif?.widget == null) ? (<span className="font-semibold text-white">{notif?.body}</span>) :
              (<span className="font-semibold text-white">
                {notif?.body}: {notif?.widget?.value}
              </span>)
            }
          </div>
        );
      case "auto_session_post":
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.post}`;
            }}
          >
            {notif?.body}
          </div>
        );
      case "hilo_notif":
        return (
          <div className="font-poppins text-[14px] text-[#D9D9D9] hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              if (notif?.link != null && notif?.link.length > 0)
                window.location.href = notif?.link;
            }}
          >
            {notif?.body}
          </div>
        );
      case "post_comment":
        return (
          <div className="font-poppins text-white align-bottom hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.comment?.post}`;
            }}
          >
            <div className="font-poppins text-[14px] text-[#D9D9D9]">
              <span className="font-semibold text-white">{notif?.tagged_user?.username}{' '}</span>
              commented on your post "{truncateStr(notif?.comment?.body || "", 15)}"
            </div>
          </div>
        );
      case "commend":
        return (
          <div className="font-poppins text-white align-bottom hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
            }}
          >
            <div className="font-poppins text-[14px] text-[#D9D9D9]">
              <span className="font-semibold text-white">{notif?.tagged_user?.username}{' '}</span>
              wrote on your Commend wall "{truncateStr(notif?.commend || "", 15)}"
            </div>
          </div>
        );
      case "post_react":
        return (
          <div className="font-poppins text-white align-bottom hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.post}`;
            }}
          >
            <div className="font-poppins text-[14px] text-[#D9D9D9]">
              <span className="font-semibold text-white">{notif?.tagged_user?.username}{' '}</span>
              reacted to your post.
            </div>
          </div>
        );
      case "batch_post_react":
        const parts = splitStr(notif?.body, "reacted to your post!");
        return (
          <div className="font-poppins text-white align-bottom hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              markAsRead(notif);
              window.location.href = `/posts/${notif?.post}`;
            }}
          >
            <div className="font-poppins text-[14px] text-[#D9D9D9]">
              <span className="font-semibold text-white">{parts[0]}{' '}</span>
              {parts[1]}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="md:px-4 xl:px-8 relative">
      <Navbar userInfo={userInfo} showNotifs={showNotifs} setShowNotifs={setShowNotifs} hasNew={hasNew} markAllAsRead={markAllAsRead} />
      {showNotifs && (
        <div className="mb-10 min-h-40 min-w-screen sm:min-h-0 sm:min-w-40 sm:border-[1px] sm:border-[hiloLoginBorder] sm:rounded-lg p-3 sm:right-64 bg-[#232323] flex flex-col gap-4 z-40 sm:max-w-80 overflow-hidden sm:fixed">
          <div className="text-white text-[20px] font-poppins font-medium">Notifications</div>
          <div className="flex flex-col gap-2 sm:overflow-y-auto sm:max-h-[75vh]">
            {notifs.map((notif: Notification) => {
              // Hide inactive friend requests
              if (notif?.notif_type === "friend_request" && !notif?.friend_request?.is_active)
                return null;

              let notifPic = "";
              // automated session, widget change take precedence
              if (notif?.notif_type === "widget_change") {
                if (notif?.media_url == null || notif?.media_url === "")
                  notifPic = notif?.widget?.media_url || "";
                else
                  notifPic = notif?.media_url;
              } else if (notif?.notif_type === "auto_session_post")
                notifPic = AnonymousAvatar;
              // then hilo platform notif
              else if (notif?.notif_type !== "auto_session_post" && notif?.tagged_user == null)
                notifPic = HiloIcon;
              // lastly tagged user pfp
              else
                notifPic = notif?.tagged_user?.profile_pic;

              return (
                <div key={notif.id} className="flex flex-row gap-2 items-start">
                  <div className="rounded-full overflow-hidden w-10 h-10 flex-shrink-0"
                    onClick={(e) => {
                      e.preventDefault();
                      if (notif?.notif_type === "friend_request" && notif?.friend_request?.is_active)
                        window.location.href = `/profile/${notif?.tagged_user?.username}`;
                    }}
                  >
                    <img 
                      src={notifPic}
                      className={(notif?.notif_type === "friend_request") ? 'w-full h-full object-cover hover:cursor-pointer' : 'w-full h-full object-cover'}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    {renderNotifBody(notif)}
                    <div className="text-[10px] text-[#C9C9C9] italic">
                      {timeAgo(notif?.date_created)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {(!showNotifs || !isMobile) && <div>{children}</div>}
    </div>
  )
}

export default MainLayout;
